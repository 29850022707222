<template>
  <!-- eslint-disable max-len -->
  <div class="service-and-support" v-if="info">
    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ getData.section_headline }}
        </h2>

        <div class="text" v-html="getData.section_content"></div>

      </div>

      <div class="section__content">
        <div class="support-timeline">
          <div class="timeline-stage">
            <div class="stage__icon">
              <img class="image" src="images/service-support/plan.png" alt="">
            </div>
            <div class="stage__name">
              <h4>
                {{ getData.stage_1_headline }}
              </h4>
            </div>

            <div class="stage__content">
              <h4>
                {{ getData.stage_1_headline }}
              </h4>

              <div v-html="getData.stage_1_content"></div>
            </div>

            <span
              class="stage__toggle"
              @click="openDetail($event)">
            </span>
          </div>

          <div class="timeline-stage">
            <span
              v-if="getData.site_prep_trigger_label"
              class="stage__cta ra-gtm-event"
              data-toggle="modal"
              data-target="#preparation-modal"
              data-gtm-section="Service & Support"
              data-gtm-subsection=""
              data-gtm-label="Open Site preparation Modal"
              @click="clickTracked($event)">
              <span>
                {{ getData.site_prep_trigger_label }}
              </span>
            </span>
            <div class="stage__icon">
              <img class="image" src="images/service-support/configure.png" alt="">
            </div>
            <div class="stage__name">
              <h4>
                {{ getData.stage_2_headline }}
              </h4>
            </div>

            <div class="stage__content">
              <h4>
                {{ getData.stage_2_headline }}
              </h4>

              <div v-html="getData.stage_2_content"></div>
            </div>

            <span
              class="stage__toggle"
              @click="openDetail($event)">
            </span>
          </div>

          <span
            class="stage__cta stage__cta--big ra-gtm-event"
            data-toggle="modal"
            data-target="#plans-modal"
            data-gtm-section="Service & Support"
            data-gtm-subsection=""
            data-gtm-label="Open Service and support Modal"
            @click="clickTracked($event)">
            <span>
              {{ getData.plans_breakdown_trigger }}
            </span>
          </span>

          <div class="timeline-stage">
            <div class="stage__icon">
              <img class="image" src="images/service-support/install-test.png" alt="">
            </div>
            <div class="stage__name">
              <h4>
                {{ getData.stage_3_headline }}
              </h4>
            </div>

            <div class="stage__content">
              <h4>
                {{ getData.stage_3_headline }}
              </h4>

              <div v-html="getData.stage_3_content"></div>
            </div>

            <span
              class="stage__toggle"
              @click="openDetail($event)">
            </span>
          </div>

          <div class="timeline-stage">
            <div class="stage__icon">
              <img class="image" src="images/service-support/launch.png" alt="">
            </div>
            <div class="stage__name">
              <h4>
                {{ getData.stage_4_headline }}
              </h4>
            </div>

            <div class="stage__content">
              <h4>
                {{ getData.stage_4_headline }}
              </h4>

              <div v-html="getData.stage_4_content"></div>
            </div>

            <span
              class="stage__toggle"
              @click="openDetail($event)">
            </span>
          </div>

          <div class="timeline-stage timeline-stage--dark">
            <div class="stage__icon">
              <img class="image" src="images/service-support/production-ramp.png" alt="">
            </div>
            <div class="stage__name">
              <h4>
                {{ getData.stage_5_headline }}
              </h4>
            </div>

            <div class="stage__content">
              <h4>
                {{ getData.stage_5_headline }}
              </h4>

              <div v-html="getData.stage_5_content"></div>
            </div>

            <span
              class="stage__toggle"
              @click="openDetail($event)">
            </span>
          </div>

          <div class="timeline__footer">
            <div class="timeline__group timeline__group--init">
              <h4>
                Sales Process
              </h4>

              <p>
                Establish Business Goals
              </p>
            </div>

            <div class="timeline__group">
              <h4>
                Business Transformation
              </h4>

              <p>
                Accelerate Digital Transformation
              </p>
            </div>

            <div class="timeline__group timeline__group--production">
              <h4>
                Production Ramp
              </h4>

              <p>
                Achieve Production Volume, OEE
              </p>
            </div>

            <div class="timeline__group timeline__group--final">
              <h4>
                {{ getData.additional_headline }}
              </h4>

              <div class="stage__content">
                <div v-html="getData.additional_content"></div>
              </div>

              <span
                class="stage__toggle"
                @click="openDetail($event)">
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>

    <Modal idModal="preparation-modal">
      <div class="preparation__img">
        <img :src="`${cmsURL}assets/${getData.printer_image}`" alt="">
      </div>
      <div class="preparation__content">
        <h4 class="title title--small">
          {{ getData.modal_prep_headline_label }}
        </h4>
        <h2 class="title title--white">
          {{ getData.modal_prep_headline }}
        </h2>
        <small v-html="getData.modal_prep_content"></small>
      </div>

      <ShareButton
        v-if="getData.site_prep_shareable_pdf"
        :shareLabel="`${$t('main-nav.service-support')} - ${getData.site_prep_trigger_label} ${printerName}`"
        :shareLink="`${cmsURL}assets/${getData.site_prep_shareable_pdf}`"
        :hash="hashify(`Service & Support - Site Prep ${printerName}`)"
        @share-this="shareThis" />
    </Modal>

    <Modal idModal="plans-modal">
      <h2 class="title title--white text-center">
        {{ getData.modal_plans_headline }}
      </h2>

      <div class="text">
        <p>
          Maintain high uptime with HP’s global technical support services that back your press with fast ramp up, 24/7 remote assistance, and on-site support.
        </p>
      </div>

      <table class="modal-table support-table">
        <tr>
          <th>
            <h5>
              Comes With The Press
            </h5>
          </th>
          <th>
            <h5>
             Service Contract
            </h5>
          </th>
          <th>
            <h5>
              Professional Services
            </h5>
          </th>
        </tr>
        <tr>
          <td>
            <p>
              Business Transformation Services
            </p>
          </td>
          <td rowspan="3">
            <p>
              Manage print operations:<br>
            </p>
            <p>
              Support services for the press & DFE<br>
              Account Service Management<br>
              Preventative Maintenance<br>
              Ongoing software and hardware updates
            </p>
          </td>
          <td>
              <p>
                Boost production
              </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              Training & service to get you started
            </p>
          </td>
          <td>
            <p>
              Excel in color management and paper handling
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              Tools to get you started using smart technology
            </p>
          </td>
          <td>
            <p>
              Integrate workflow solutions
            </p>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <p>
              Remote support powered by xRServices
            </p>
          </td>
          <td>
            <p>
              Production automation & expansion
            </p>
          </td>
        </tr>
      </table>

      <ShareButton
        v-if="getData.plans_shareable_pdf"
        :shareLabel="`${$t('main-nav.service-support')} - ${getData.plans_breakdown_trigger} ${printerName}`"
        :shareLink="`${cmsURL}assets/${getData.plans_shareable_pdf}`"
        :hash="hashify(`Service & Support - Service Modal ${printerName}`)"
        @share-this="shareThis" />
    </Modal>

    <ShareButton
      v-if="getData.shareable_pdf"
      :shareLabel="`${$t('main-nav.service-support')} - ${printerName}`"
      :shareLink="`${cmsURL}assets/${getData.shareable_pdf}`"
      :hash="hashify(`Service & Support ${printerName}`)"
      @share-this="shareThis" />
  </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import Modal from '@/components/modal/Modal.vue';
import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'ServiceSupport',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton,
    Modal,
  },
  data() {
    return {
      info: null,
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/service_and_support`, {
      params: {
        fields: '*,translations.*,printer.printers_id',
      },
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.showMainTabs();
  },
  methods: {
    openDetail({ target }) {
      target.classList.toggle('active');
      $(target).parent().find('.stage__icon').fadeToggle();
      $(target).parent().find('.stage__content').fadeToggle();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/service-support.scss';
</style>
