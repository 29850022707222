<template>
  <Overlay
    class="preloader"
    v-show="!hasPresentationStarted">
    <img src="images/hp-blueprint-logo.svg" alt="">
  </Overlay>

  <Overlay/>

  <div :class="['main-wrap', {'sidebar': isSidebarOpen}] ">
    <MainHeader
      :isSidebarOpen="isSidebarOpen"
      @toggle-sidebar="toggleSidebar" />

    <MainNav :class="{'sidebar': isSidebarOpen} "/>
    <div class="wrap-main-content">
      <div class="main-content__item">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component
              class="section"
              :sidebar="isSidebarOpen"
              :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>

  <MainSidebar
    :class="{'active': isSidebarOpen}"
    @new-presentation="startNewPresentation" />

  <Login
    :currentForm="currentLoginForm"
    @change-form="changeCurrentForm"
    @skip="startPresentation(true)"
    @start-presentation="startPresentation"/>
</template>

<script>
import './scss/_base.scss';

import Overlay from './components/ui/Overlay.vue';

// Login Form
import Login from './views/Login.vue';

// Navigation Items
import MainHeader from './components/Header.vue';
import MainNav from './components/MainNav.vue';
import MainSidebar from './components/MainSidebar.vue';

export default {
  name: 'App',
  components: {
    Overlay,
    Login,
    MainHeader,
    MainNav,
    MainSidebar,
  },
  data() {
    return {
      isSidebarOpen: false,
      hasPresentationStarted: false,
      currentLoginForm: 'login',
    };
  },
  methods: {
    changeCurrentForm(payload) {
      this.currentLoginForm = payload;
    },
    closeShare() {
      const toggler = document.getElementById('share-cart');
      toggler.classList.remove('show');
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    startPresentation(isBlank) {
      this.hasPresentationStarted = true;

      if (isBlank) {
        const blankData = {
          email: '',
          firstname: '',
          lastname: '',
          title: '',
          phone: '',
          team: [],
        };

        this.$store.commit('fillCompanyData', blankData);
      }
    },
    startNewPresentation() {
      this.currentLoginForm = 'start';
      this.hasPresentationStarted = false;
    },
  },
};
</script>

<style lang="scss">
.main-wrap {
  position: relative;
  right: 0;
  transition: all .3s ease;

  &.sidebar {
    right: 320px;
  }
}

.main-content__item {
  display: block;
  min-height: 100vh;

  .section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 175px 0 145px;
    min-height: 100vh;

    @media (min-height: 800px) {
      padding: 180px 0 150px;
    }

    &--top {
      align-items: flex-start;
      padding: 115px 0 145px;

      @media (min-height: 800px) {
        padding: 120px 0 150px;
      }
    }
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all .3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;
}
</style>
