<template>
  <Modal idModal="digitalPrint-3">
    <h2 class="title title--white text-center">
      Produce corrugated packaging better
    </h2>
    <div class="corrugated-packaging">
      <div class="corrugated-packaging__column">
        <div class="corrugated-packaging__column-content corrugated-packaging__column-content--1 corrugated-packaging__column-content--left">
          <div class="corrugated-packaging__text">
            <p>
              Reduction of costs for improved cost of a box&nbsp;economics
            </p>
          </div>
          <div class="corrugated-packaging__icon">
            <img src="images/why-digital/digital-value/corrugated-packaging-icon-1.png?v=1" alt="">
          </div>
        </div>
        <div class="corrugated-packaging__column-content corrugated-packaging__column-content--2 corrugated-packaging__column-content--left">
          <div class="corrugated-packaging__text">
            <p>
              On demand capacity to adjust orders on-the-fly and balance capacity throughout the plan
            </p>
          </div>
          <div class="corrugated-packaging__icon">
            <img src="images/why-digital/digital-value/corrugated-packaging-icon-2.png?v=1" alt="">
          </div>
        </div>
        <div class="corrugated-packaging__column-content corrugated-packaging__column-content--1 corrugated-packaging__column-content--left">
          <div class="corrugated-packaging__text">
            <p>
              Short lead-times for more flexible supply chains and addressing market opportunities
            </p>
          </div>
          <div class="corrugated-packaging__icon">
            <img src="images/why-digital/digital-value/corrugated-packaging-icon-3.png?v=1" alt="">
          </div>
        </div>
      </div>
      <div class="corrugated-packaging__middle">
        <img src="images/why-digital/digital-value/corrugated-packaging.png?v=1" alt="">
      </div>
      <div class="corrugated-packaging__column">
        <div class="corrugated-packaging__column-content corrugated-packaging__column-content--3">
          <div class="corrugated-packaging__text corrugated-packaging__text--right">
            <p>
              Reduction of waste throughout the lifecycle of the packaging.
            </p>
          </div>
          <div class="corrugated-packaging__icon corrugated-packaging__icon--left">
            <img src="images/why-digital/digital-value/corrugated-packaging-icon-4.png?v=1" alt="">
          </div>
        </div>
        <div class="corrugated-packaging__column-content corrugated-packaging__column-content--4">
          <div class="corrugated-packaging__text corrugated-packaging__text--right">
            <p>
              Freedom to make frequent and last-minute changes for efficient design, prepress, and sampling.
            </p>
          </div>
          <div class="corrugated-packaging__icon corrugated-packaging__icon--left">
            <img src="images/why-digital/digital-value/corrugated-packaging-icon-5.png?v=1" alt="">
          </div>
        </div>
        <div class="corrugated-packaging__column-content corrugated-packaging__column-content--3">
          <div class="corrugated-packaging__text corrugated-packaging__text--right">
            <p>
              Appealing packaging that meets consumer demands and stands out on the shelf
            </p>
          </div>
          <div class="corrugated-packaging__icon corrugated-packaging__icon--left">
            <img src="images/why-digital/digital-value/corrugated-packaging-icon-6.png?v=1" alt="">
          </div>
        </div>
      </div>
    </div>
   </Modal>
</template>

<script>
import Modal from '@/components/modal/Modal.vue';

export default {
  name: 'WhyDigitalPrintModal3',
  components: {
    Modal,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/why-digital/digital-value/why-digital-print/modal3';
</style>
