<template>
  <div class="tech-section--ink" v-if="info">
    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ getData.title }}
        </h2>
      </div>

      <div class="section__content">
        <div class="row px-5 align-items-center">
          <div class="col-12">
            <div class="ink__intro-content">
              <h4 class="title title--medium title--blue">
                {{ getData.intro_title }}
              </h4>
              <br>
              <div v-html="getData.intro_content"></div>
            </div>
          </div>

          <div
            class="col-md-4"
            v-for="(item, index) in getData.content"
            :key="index">
            <div class="brilliant-ink js-brilliant-ink">
              <div class="brilliant-ink__img">
                <img :src="item.poster" alt="">
              </div>
              <div class="brilliant-ink__text">
                <h3>
                  {{ item.name }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ShareButton
      v-if="getData.shareable_pdf"
      :shareLink="`${cmsURL}assets/${getData.shareable_pdf}`"
      :shareLabel="`${$t('main-nav.technology')} - ${$t('technology.submenu.ink')} ${printerName}`"
      :hash="hashify(`${$t('main-nav.technology')} - ${$t('technology.submenu.ink')} ${printerName}`)"
      @share-this="shareThis" />
  </div>
</template>

<script>
import axios from 'axios';
import { gsap } from 'gsap';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';
import { showElement } from '@/common/animations';
import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'BrilliantInk',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton,
  },
  data() {
    return {
      info: null,
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/technology_brilliant_ink`, {
      params: {
        fields: '*,translations.*,printer.printers_id',
      },
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    gsap.timeline()
      .add(showElement('.js-brilliant-ink', 'center', {
        stagger: 0.5,
      }))
      .delay(0.5);

    this.showMainTabs();
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/technology/brilliantInk.scss';
</style>
