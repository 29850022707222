// const BASEURL = 'https://corrugated.hp-blueprint.com/';
const BASEURL = 'https://corrugated.hp-blueprint.com/';
const BASEAPIURL = 'https://corrugated.hp-blueprint.com/api/';
const EXT = '.php';
const CMS = 'https://admin-corrugated.hp-blueprint.com/';

export {
  BASEURL,
  BASEAPIURL,
  EXT,
  CMS,
};
