<template>
  <div class="row justify-content-center">
    <div class="col-12 col-xl-10">
      <div class="growth-graphic">
        <svg viewBox="0 0 189 189" class="badge-500">
          <circle class="st0" cx="94.5" cy="94.5" r="93.5" />
          <circle class="st1" cx="94.5" cy="94.5" r="87.5" />
          <text x="50%" y="30%" text-anchor="middle" font-size="24" fill="#FFF">
            <tspan x="50%" dy="-0.6em">#1</tspan>
            <tspan x="50%" dy="1.2em">Market share</tspan>
            <tspan x="50%" dy="1.2em">by volume</tspan>
          </text>

          <text x="50%" y="55%" text-anchor="middle" dy="4em" font-size="16" fill="#FFF">
            <tspan x="50%" dy="1.2em">1.2B sqm</tspan>
            <tspan x="50%" dy="1.2em">printed since 2019</tspan>
          </text>
        </svg>

        <div class="growth-year">
          <span>2019</span>
          <div class="bar bar--1"></div>
        </div>
        <div class="growth-year">
          <span>2020</span>
          <div class="bar bar--2"></div>
        </div>
        <div class="bg">
          <div class="growth-year">
            <span>2021</span>
            <div class="bar bar--3"></div>
          </div>
          <span class="title">
            Digital is growing faster than the market, HP is growing faster than the digital market
          </span>
          <span class="note">
            59% production growth from 2021-2024
          </span>
          <div class="growth-year">
            <span>2022</span>
            <div class="bar bar--4"></div>
          </div>
          <div class="growth-year">
            <span>2023</span>
            <div class="bar bar--5"></div>
          </div>
          <div class="growth-year">
            <span>2024</span>
            <div class="bar bar--6"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'VolumeGrowth',
};
</script>

<style lang="scss" scoped>
@import '@/scss/views/why-digital/customers/volume-growth';
</style>
