<template>
  <!-- eslint-disable max-len -->
  <div v-if="info">
    <div class="container-xl">
      <div class="section__title text-center">
        <template v-if="subSections[currentSubsection].headline">
          <h2 class="title">
            {{ subSections[currentSubsection].headline }}
          </h2>
        </template>
        <template v-else>
          <h2 class="title">
            {{ subSections[currentSubsection].label }}
          </h2>
        </template>
      </div>

      <SubsectionNavLeft
        :currentComponent="subSections[currentSubsection]"
        gtmSubsection="HP TIJ"
        parent="Technology"
        :subSections="subSections"
        @click-subnav="changeTab" >
          <component
          :data="getData"
          :is="subSections[currentSubsection].component" />
      </SubsectionNavLeft>
    </div>

    <ShareButton
      v-if="getData.shareable_pdf"
      :hash="hashify(`${$t('main-nav.technology')} - ${$t('technology.submenu.hp-tij')} ${printerName}`)"
      :shareLabel="`${$t('main-nav.technology')} - ${$t('technology.submenu.hp-tij')} ${printerName}`"
      :shareLink="`${baseURL}${getData.video}\n${cmsURL}assets/${getData.shareable_pdf}`"
      @share-this="shareThis" />

  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import ShareButton from '@/components/ui/ShareButton.vue';
import SubsectionNavLeft from '@/layout/SubsectionNavLeft.vue';

import TIJVideo from './tij/TIJVideo.vue';
import HDNA from './tij/HDNA.vue';
import NozzleRedundancy from './tij/NozzleRedundancy.vue';
import Comparisson from './tij/Comparisson.vue';
import Printheads from './tij/Printheads.vue';

export default {
  name: 'HPTIJ',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton,
    SubsectionNavLeft,
    TIJVideo,
    HDNA,
    NozzleRedundancy,
    Comparisson,
    Printheads,
  },
  data() {
    return {
      baseURL: endpoints.BASEURL,
      currentSubsection: 0,
      info: null,
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/technology_tij`, {
      params: {
        fields: '*,translations.*,printer.printers_id',
      },
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.showMainTabs();
  },
  methods: {
    changeTab(payload) {
      this.currentSubsection = payload.id;
    },
  },
  computed: {
    /**
     * The items in the array MUST match the components name files
     * these values are trimmed and passed as components names.
     */
    subSections() {
      if (this.getData.printheads_title) {
        return [
          {
            id: 0,
            component: 'TIJVideo',
            label: this.getData.title_tij_video,
            shareLabel: this.getData.title_tij_video,
          },
          {
            id: 1,
            component: 'NozzleRedundancy',
            label: this.getData.nozzle_title,
            shareLabel: this.getData.nozzle_title,
          },
          {
            id: 2,
            component: 'Comparisson',
            label: this.getData.comparison_title,
            shareLabel: this.getData.comparison_title,
          },
          {
            id: 3,
            component: 'Printheads',
            label: this.getData.printheads_title,
            headline: this.getData.printheads_headline,
            shareLabel: this.getData.printheads_title,
          },
        ];
      }

      return [
        {
          id: 0,
          component: 'TIJVideo',
          label: this.getData.title_tij_video,
          shareLabel: this.getData.title_tij_video,
        },
        {
          id: 1,
          component: 'NozzleRedundancy',
          label: this.getData.nozzle_title,
          shareLabel: this.getData.nozzle_title,
        },
        {
          id: 2,
          component: 'Comparisson',
          label: this.getData.comparison_title,
          shareLabel: this.getData.comparison_title,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
  .wrapper-sub {
    display: flex;
    align-items: flex-start;
  }
</style>
