<template>
  <div class="sustainability-circle">

    <transition name="fade">
      <Loading v-if="!isReady" />
    </transition>

    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ $t('main-nav.sustainability') }}
        </h2>
      </div>

      <template v-if="items">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="circle-graph">
              <svg
                aria-hidden="true"
                role="img"
                class="icon-box"
                viewBox="0 0 1024 992">
                <!-- eslint-disable-next-line max-len -->
                <path d="M1023.5 208.5Q1022 192 1008 184L723 6q-19-12-37 2L512 149L338 8q-18-14-37-2L16 184Q2 193 .5 209T12 236l165 138L36 486q-13 10-12.5 26T38 537l131 88q-11 9-11 23v129q0 17 15 27l322 182q8 5 17 5q4 0 8.5-1t8.5-4l318-182q15-10 15-27V620l124-83q13-9 14-25q0-8-3-15t-9-11L847 374l165-138q13-11 11.5-27.5zM737 375L512 515L287 375l225-147zM87 215L315 72l143 115l-228 148zm143 199l226 142l-128 98l-218-145zm-8 246l89 60q9 5 19 5t19-6l129-99v282L222 759v-99zm576 99L542 904V617l133 102q4 3 9 4.5t10 1.5t9.5-1.5t8.5-3.5l86-58v97zm115-251L695 654l-127-98l226-142zM794 335L566 187L709 72l228 143z">
                </path>
              </svg>

              <!-- eslint-disable-next-line max-len -->
              <div v-for="(item, index) in translatedCircle" :key="index" :class="['circle__quarter', `circle__quarter--${item.content.number}`, {'active': activeStep === item.content.number}]">
                <div class="btn" @click="activatePoint(item.content.number)">
                  <span>{{ item.content.number }}</span>
                  <p>{{ item.content.title }}</p>
                </div>

                <svg
                  aria-hidden="true"
                  role="img"
                  class="icon-arrow"
                  viewBox="0 0 140 115">
                  <!-- eslint-disable-next-line max-len -->
                  <path class="st0" d="M1,113.9c0,1.5,2.1,1.7,2.3,0.2c0.3-3.2,1-6.3,1.7-9.3C14.1,81.6,32.7,63.5,52.6,49C69.8,36.4,94,19.2,116.7,23.3l0,0l0.2,0c1.6,4.3,4.8,8.1,7.7,11.8c0.5,0.6,2,0.8,2.5-0.1c3.9-6.8,8.4-13.5,11.7-20.8c0.5-1.1-0.2-1.9-0.9-2.2c-2.1-1.1-4.1-2.2-6.2-3.4l-1.5-0.9c-5.7-3.1-11.4-6.2-17.8-7.8c-1.3-0.3-2.9,0.8-2.1,2.2c1.6,3.2,2.7,6.6,4.1,9.7C64.4,10,8.6,53.7,1.8,104.5c-0.6,2-1.4,4-1.8,6.1c-0.1,0.9,0.4,1.6,1.2,1.6l0,0L1,113.9z M11.7,84.2c17.9-40.1,57.5-66.9,103.1-69.7c0.6-0.1,1-0.5,1.1-1c0.6-0.1,1.2-0.8,0.9-1.8c-1.1-2.6-1.6-5.1-2.4-7.7c7.1,2.5,13.7,6.6,20.4,9.9c-2.7,5.8-6.1,11.3-9.4,16.9c-2.3-3.1-4.5-6.4-6.6-9.8c-0.6-1.2-3-0.7-2.6,0.9l0,0l-0.5-0.2c-9.7-4-21-0.5-30.3,3.6c-11.9,5.4-23.1,12.2-33.8,20C37.2,56,22.2,68.8,11.7,84.2z"/>
                </svg>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <!-- eslint-disable-next-line max-len -->
            <div v-for="(item, index) in translatedCircle" :key="index" class="section__content" v-show="activeStep === item.content.number">
              <h6 class="eyebrow">
                {{ item.content.title }}
              </h6>
              <h1 class="title">
                {{ item.content.headline }}
              </h1>
              <div class="mb-4">
                <div v-html="item.content.description"></div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <ShareButton
      :hash="hashify(`Sustainability Circle ${currentPrinter}`)"
      :shareLabel="`${$t('main-nav.sustainability')} - ${$t('sustainability.submenu.circle')}`"
      :shareLink="`${baseURL}shareable/sustainability/circle_of_sustainability.pdf`"
      @share-this="shareThis" />
  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import ShareButton from '@/components/ui/ShareButton.vue';
import Loading from '@/components/ui/Loading.vue';

export default {
  name: 'Intro',
  mixins: [defaultMixins, filters],
  components: {
    ShareButton,
    Loading,
  },
  data() {
    return {
      cmsURL: endpoints.CMS,
      baseURL: endpoints.BASEURL,
      items: [],
      activeStep: 1,
    };
  },
  created() {
    // Get Circle text
    axios.get(`${endpoints.CMS}items/sustainability_circle`, {
      params: {
        fields: '*,translations.*',
      },
    })
      .then(({ data: { data } }) => {
        this.items = data;
      })
      .then(() => { this.isReady = true; });
  },
  mounted() {
    this.showMainTabs();
  },
  computed: {
    translatedCircle() {
      return this.items
        .map((item) => {
          // Set English for fallback
          const translateditem = item.translations
            .filter((i) => i.languages_code === this.$i18n.locale)[0];
          // Filter translated items
          const fallbackitem = item.translations
            .filter((i) => i.languages_code === this.$i18n.fallbackLocale)[0];

          return {
            ...item,
            content: translateditem || fallbackitem,
          };
        });
    },
  },
  methods: {
    activatePoint(idx) {
      this.activeStep = idx;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/sustainability/circle';
</style>
