<template>
  <div
    v-if="info"
    class="intro">
    <div class="container-xl">
      <div class="row">
        <div class="next-steps-bg">
          <img src="https://admin-corrugated.hp-blueprint.com/assets/6e961f45-8a6a-4607-9e4f-16da2f2df879" alt="">
        </div>
        <div class="offset-md-4 offset-lg-6 col-md-8 col-lg-6">
          <h2 class="title title--big">
            {{ getData.headline }}
          </h2>
          <br>
          <div class="section__content">
            <div class="next-steps-content" v-html="getData.content"></div>
          </div>
          <div class="button-group">
            <Button
              class="sm-btn"
              :href="getData.button_1_link"
              target="_blank">
              {{ getData.button_1 }}
            </Button>
            <Button
              class="sm-btn"
              :href="getData.button_2_link"
              target="_blank">
              {{ getData.button_2 }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { defaultMixins } from '@/common/mixins';

import Button from '@/components/ui/Button.vue';

export default {
  name: 'NextSteps',
  mixins: [defaultMixins],
  components: {
    Button,
  },
  data() {
    return {
      info: null,
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/next_steps`, {
      params: {
        fields: '*,translations.*,printer.printers_id',
      },
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/nextSteps.scss';
</style>
