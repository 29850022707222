<template>
  <Modal idModal="digitalPrint-1">
    <h2 class="title title--white text-center">
      <!-- {{ getData.modal_ }} -->
      Produce corrugated packaging at a lower cost
    </h2>
    <div class="lower-cost">
      <div class="lower-cost__column">
        <div class="lower-cost__column-headline">
          <h4 class="title title--smedium title--blue">
            Traditional Supply Chain
          </h4>
          <p>
            with Conventional Printing
          </p>
        </div>
        <div class="lower-cost__column-colors">
          <div class="lower-cost__column-color lower-cost__column-color--red-1"></div>
          <div class="lower-cost__column-color lower-cost__column-color--white-1"></div>
          <div class="lower-cost__column-color lower-cost__column-color--white-2"><span></span></div>
          <div class="lower-cost__column-color lower-cost__column-color--white-3"><span></span></div>
          <div class="lower-cost__column-color lower-cost__column-color--white-4"><span></span></div>
          <div class="lower-cost__column-color lower-cost__column-color--white-5"><span></span></div>
          <div class="lower-cost__column-color lower-cost__column-color--blue-1"></div>
        </div>
      </div>
      <div class="lower-cost__column-middle">
        <p class="lower-cost__column-middle-text-red">
          Rush Orders
        </p>
        <p>
          Obsolescence from design changes, end-of-life, activations
        </p>
        <p class="lower-cost__column-middle-no-cost">
          Printing plate costs
        </p>
        <p>
          Inventory holding cost. Incl. working capital cost
        </p>
        <p class="lower-cost__column-middle-no-cost">
          cost for reprinting aged stock
        </p>
        <p class="lower-cost__column-middle-no-cost">
          Re-packaging / over-labeling
        </p>
        <p class="lower-cost__column-middle-text-blue">
          COGS per M units, all SKU’s
        </p>
      </div>
      <div class="lower-cost__column">
        <div class="lower-cost__column-headline">
          <h4 class="title title--smedium title--blue">
            NEW Supply Chain
          </h4>
          <p>
            using Digitally Printed Packaging
          </p>
        </div>
        <div class="lower-cost__column-colors lower-cost__column-colors--right">
          <div class="lower-cost__column-color lower-cost__column-color--gray"></div>
          <div class="lower-cost__column-color lower-cost__column-color--right lower-cost__column-color--red-2"><span></span></div>
          <div class="lower-cost__column-color lower-cost__column-color--right lower-cost__column-color--white-6"><span></span></div>
          <div class="lower-cost__column-color lower-cost__column-color--right lower-cost__column-color--white-7"></div>
          <div class="lower-cost__column-color lower-cost__column-color--right lower-cost__column-color--blue-2"></div>
        </div>
      </div>
    </div>
    <div class="lower-cost__conventions">
      <div class="lower-cost__conventions-container">
        <p class="lower-cost__single-convention lower-cost__single-convention--blue">
          Known Costs
        </p>
        <p class="lower-cost__single-convention lower-cost__single-convention--white">
          Variable Costs
        </p>
        <p class="lower-cost__single-convention lower-cost__single-convention--red">
          Unexpected Costs
        </p>
        <p class="lower-cost__single-convention lower-cost__single-convention--no-cost">
          No cost
        </p>
      </div>
    </div>
   </Modal>
</template>

<script>
import Modal from '@/components/modal/Modal.vue';

export default {
  name: 'WhyDigitalPrintModal1',
  components: {
    Modal,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/why-digital/digital-value/why-digital-print/modal1';
</style>
