<template>
  <div class="install-base-map">
    <!-- eslint-disable max-len  -->
    <div class="install-base-map-wrap">
      <img src="images/why-digital/customers/install-base-map/worldmap.png" alt="install base map">

      <div class="install-base-map-flagpoles">
        <div class="install-base-map-flagpole" :style="`left: ${item.posX}%; top: ${item.posY}%; height: ${item.height}%;`" v-for="(item, index) in flagpoles" v-bind:key="index" >
          <div class="install-base-map-flagpole-locator box-color" :class="item.color+' '+item.grow">
          </div>
          <div class="install-base-map-flagpole-stick box-color" :class="item.color">
          </div>
        </div>
      </div>

      <div class="install-base-map-flags" style="display:block;" >
        <div class="install-base-map-flag"
        :style="`left: ${item.posX}%; top: ${item.posY}%; width: ${item.width}px;`"
        v-for="(item, index) in flags"
        v-bind:key="index"
        @click="selectFlag(item)"
        data-gtm-section="Why Digital"
        data-gtm-subsection="Customers"
        :data-gtm-label="`Click ${item.shortName} flag`"
        >
          <div class="install-base-map-flag-content" :class="item.color" >
            <p class="install-base-map-flag-content-title">
              {{ item.shortName }}
            </p>
            <p class="install-base-map-flag-content-subtitle">
              <span>
                <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.58802 19.0903C6.58802 19.0903 0 13.2913 0 7.58529C0 5.57355 0.76463 3.6442 2.12568 2.22168C3.48673 0.799163 5.33271 0 7.25753 0C9.18234 0 11.0283 0.799163 12.3894 2.22168C13.7504 3.6442 14.5151 5.57355 14.5151 7.58529C14.5151 13.2913 7.92703 19.0903 7.92703 19.0903C7.56053 19.443 6.95725 19.4392 6.58802 19.0903ZM7.25753 10.9039C7.67449 10.9039 8.08738 10.818 8.47261 10.6512C8.85784 10.4845 9.20787 10.24 9.50271 9.93187C9.79755 9.62372 10.0314 9.25788 10.191 8.85525C10.3506 8.45263 10.4327 8.02109 10.4327 7.58529C10.4327 7.14949 10.3506 6.71796 10.191 6.31533C10.0314 5.91271 9.79755 5.54687 9.50271 5.23871C9.20787 4.93056 8.85784 4.68611 8.47261 4.51934C8.08738 4.35256 7.67449 4.26673 7.25753 4.26673C6.41542 4.26673 5.6078 4.61636 5.01234 5.23871C4.41688 5.86106 4.08236 6.70515 4.08236 7.58529C4.08236 8.46543 4.41688 9.30952 5.01234 9.93187C5.6078 10.5542 6.41542 10.9039 7.25753 10.9039Z" fill="white"/>
                </svg>
              </span>
              {{ item.location }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="install-base-map-legend">
        <div class="install-base-map-legend-presses">
            <div class="install-base-map-legend-press-item" v-for="item in presses" v-bind:key="item.id" >
              <span :class="item.color">
                <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.58802 19.0903C6.58802 19.0903 0 13.2913 0 7.58529C0 5.57355 0.76463 3.6442 2.12568 2.22168C3.48673 0.799163 5.33271 0 7.25753 0C9.18234 0 11.0283 0.799163 12.3894 2.22168C13.7504 3.6442 14.5151 5.57355 14.5151 7.58529C14.5151 13.2913 7.92703 19.0903 7.92703 19.0903C7.56053 19.443 6.95725 19.4392 6.58802 19.0903ZM7.25753 10.9039C7.67449 10.9039 8.08738 10.818 8.47261 10.6512C8.85784 10.4845 9.20787 10.24 9.50271 9.93187C9.79755 9.62372 10.0314 9.25788 10.191 8.85525C10.3506 8.45263 10.4327 8.02109 10.4327 7.58529C10.4327 7.14949 10.3506 6.71796 10.191 6.31533C10.0314 5.91271 9.79755 5.54687 9.50271 5.23871C9.20787 4.93056 8.85784 4.68611 8.47261 4.51934C8.08738 4.35256 7.67449 4.26673 7.25753 4.26673C6.41542 4.26673 5.6078 4.61636 5.01234 5.23871C4.41688 5.86106 4.08236 6.70515 4.08236 7.58529C4.08236 8.46543 4.41688 9.30952 5.01234 9.93187C5.6078 10.5542 6.41542 10.9039 7.25753 10.9039Z" fill="white"/>
                </svg>
              </span>
              <p>
                {{ item.series }}
              </p>
            </div>
        </div>
    </div>
    <div ref="mapModal" class="install-base-map-modal" @click="closeModal()" >
      <div ref="mapModalDialog" class="install-base-map-modal-dialog">
        <div class="install-base-map-modal-content" v-if="selectedFlag">
            <button
            class="install-base-map-modal-close"
            type="button"
            @click="closeModal()"
            name="button">
            </button>
            <!-- eslint-disable max-len  -->
            <div class="logo-wrap">
              <img :src="selectedFlag.logo" :alt="selectedFlag.largeName" >
            </div>
            <h2>
              {{ selectedFlag.largeName }}
            </h2>
            <div class="locations">
              <div class="location" v-for="(item, index) in selectedFlag.locations" v-bind:key="index">
                <span class="location-icon" :class="item.color" >
                  <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.58802 19.0903C6.58802 19.0903 0 13.2913 0 7.58529C0 5.57355 0.76463 3.6442 2.12568 2.22168C3.48673 0.799163 5.33271 0 7.25753 0C9.18234 0 11.0283 0.799163 12.3894 2.22168C13.7504 3.6442 14.5151 5.57355 14.5151 7.58529C14.5151 13.2913 7.92703 19.0903 7.92703 19.0903C7.56053 19.443 6.95725 19.4392 6.58802 19.0903ZM7.25753 10.9039C7.67449 10.9039 8.08738 10.818 8.47261 10.6512C8.85784 10.4845 9.20787 10.24 9.50271 9.93187C9.79755 9.62372 10.0314 9.25788 10.191 8.85525C10.3506 8.45263 10.4327 8.02109 10.4327 7.58529C10.4327 7.14949 10.3506 6.71796 10.191 6.31533C10.0314 5.91271 9.79755 5.54687 9.50271 5.23871C9.20787 4.93056 8.85784 4.68611 8.47261 4.51934C8.08738 4.35256 7.67449 4.26673 7.25753 4.26673C6.41542 4.26673 5.6078 4.61636 5.01234 5.23871C4.41688 5.86106 4.08236 6.70515 4.08236 7.58529C4.08236 8.46543 4.41688 9.30952 5.01234 9.93187C5.6078 10.5542 6.41542 10.9039 7.25753 10.9039Z" fill="white"/>
                  </svg>
                </span>
                <div class="location-content" v-if=" item.color != 'bluegreen' ">
                  {{ item.location }}
                </div>
              </div>
            </div>
            <div class="description">
                <p>{{ selectedFlag.description }}</p>
            </div>
            <a  class="cta ra-gtm-event"
            type="button"
            name="button"
            data-gtm-section="Why Digital"
            data-gtm-subsection="Customers"
            :data-gtm-label="`Learn more detail modal: ${selectedFlag.largeName}`"
            v-if="selectedFlag.link != ''" :href="selectedFlag.link" target="_blank" >
              LEARN MORE
            </a>
          <div class="presses">
            <div class="press" v-for="(item, index) in selectedFlag.presses" v-bind:key="index">
              <span class="press-icon" :class="item.color">
                <svg witem="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.58802 19.0903C6.58802 19.0903 0 13.2913 0 7.58529C0 5.57355 0.76463 3.6442 2.12568 2.22168C3.48673 0.799163 5.33271 0 7.25753 0C9.18234 0 11.0283 0.799163 12.3894 2.22168C13.7504 3.6442 14.5151 5.57355 14.5151 7.58529C14.5151 13.2913 7.92703 19.0903 7.92703 19.0903C7.56053 19.443 6.95725 19.4392 6.58802 19.0903ZM7.25753 10.9039C7.67449 10.9039 8.08738 10.818 8.47261 10.6512C8.85784 10.4845 9.20787 10.24 9.50271 9.93187C9.79755 9.62372 10.0314 9.25788 10.191 8.85525C10.3506 8.45263 10.4327 8.02109 10.4327 7.58529C10.4327 7.14949 10.3506 6.71796 10.191 6.31533C10.0314 5.91271 9.79755 5.54687 9.50271 5.23871C9.20787 4.93056 8.85784 4.68611 8.47261 4.51934C8.08738 4.35256 7.67449 4.26673 7.25753 4.26673C6.41542 4.26673 5.6078 4.61636 5.01234 5.23871C4.41688 5.86106 4.08236 6.70515 4.08236 7.58529C4.08236 8.46543 4.41688 9.30952 5.01234 9.93187C5.6078 10.5542 6.41542 10.9039 7.25753 10.9039Z" fill="white"/>
                </svg>
              </span>
              <div class="press-content">
                {{ item.series }}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';

export default {
  name: 'InstallBaseMap',
  components: {
  },
  data() {
    return {
      info: null,
      presses: [
        {
          id: 1,
          series: 'T400S Series Press',
          color: 'blue',
        },
        {
          id: 2,
          series: 'T700i Press',
          color: 'yellow',
        },
        {
          id: 3,
          series: 'T1100 Series Press',
          color: 'green',
        },
      ],
      flags: [],
      flagpoles: [],
      selectedFlag: null,
    };
  },
  created() {
    axios.get('data/install-base-map.json')
      .then(({ data: { data } }) => {
        data.forEach((item) => {
          const presses = [];
          item.presses.forEach((prees) => {
            presses.push(this.presses.find((element) => element.id === Number(prees)));
          });
          item.locations.forEach((itemLocation) => {
            this.flags.push({
              shortName: item.shortName,
              largeName: item.largeName,
              logo: item.logo,
              description: item.description,
              link: item.link,
              presses,
              locations: item.locations,
              location: itemLocation.location,
              color: itemLocation.color,
              width: itemLocation.width,
              posX: itemLocation.posX,
              posY: itemLocation.posY,
              flagpole: itemLocation.flagpole,
            });
          });
        });
        this.flags.forEach((itemflag) => {
          const { flagpole } = JSON.parse(JSON.stringify(itemflag));
          if (flagpole !== undefined) {
            flagpole.posX = itemflag.posX;
            flagpole.color = itemflag.color;
            this.flagpoles.push(flagpole);
          }
        });
      });
  },
  methods: {
    selectFlag(item) {
      this.$refs.mapModal.style.display = 'table';
      this.selectedFlag = item;
      this.$refs.mapModal.classList.add('show');
      this.$refs.mapModalDialog.classList.add('bounce-in-up');
    },
    closeModal() {
      this.$refs.mapModal.classList.add('hide');
      this.$refs.mapModalDialog.classList.add('bounce-out-down');
      setTimeout(() => {
        this.$refs.mapModal.style.display = 'none';
        this.$refs.mapModal.classList.remove('show');
        this.$refs.mapModalDialog.classList.remove('bounce-in-up');
        this.$refs.mapModal.classList.remove('hide');
        this.$refs.mapModalDialog.classList.remove('bounce-out-down');
      }, 600);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/why-digital/customers/install-base-map';
</style>
