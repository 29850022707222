<template>
  <div>
    <!-- eslint-disable max-len -->
    <div class="container-xl">
      <div :class="['section__title', 'text-center',
      {'title--space': currentPrinter === 2}]">
        <h2 class="title">
          {{ $t('technology.paper-path.title') }}
        </h2>
      </div>
      <div class="section__content">
        <div class="paper-path">
          <img
            class="paper-path__printer"
            :src="`${cmsURL}assets/${getDataByPrinter.printer_image}`"
            alt="">
          <img
            class="paper-path__rolls paper-path__rolls--base"
            :src="`${cmsURL}assets/${getDataByPrinter.base_rollers}`"
            alt="">
          <img
            :class="['paper-path__rolls', {'active': rollers === 'surface'}]"
            :src="`${cmsURL}assets/${getDataByPrinter.surface_roller}`"
            alt="">
          <img
            :class="['paper-path__rolls', {'active': rollers === 'load'}]"
            :src="`${cmsURL}assets/${getDataByPrinter.load_cell}`"
            alt="">
          <img
            :class="['paper-path__rolls', {'active': rollers === 'nip'}]"
            :src="`${cmsURL}assets/${getDataByPrinter.nip_roller}`"
            alt="">
          <img
            class="additional"
            :src="`${cmsURL}assets/${getDataByPrinter.additional}`"
            alt="">

          <div class="paper-path__line" v-html="getDataByPrinter.svg_paper_path"></div>
        </div>

        <div class="paper-path__controls">
          <div
            v-if="getDataByPrinter.surface_roller"
            class="single-path-control"
            @mouseover="changeImg('surface')"
            @mouseleave="changeImg('init')">
            <div></div>
            <span>
              {{ $t('technology.paper-path.roll-1') }}
            </span>
          </div>
          <div
            v-if="getDataByPrinter.load_cell"
            class="single-path-control single-path-control--load-cell"
            @mouseover="changeImg('load')"
            @mouseleave="changeImg('init')">
            <div></div>
            <span>
              {{ $t('technology.paper-path.roll-2') }}
            </span>
          </div>
          <div
            v-if="getDataByPrinter.nip_roller"
            class="single-path-control single-path-control--nip"
            @mouseover="changeImg('nip')"
            @mouseleave="changeImg('init')">
            <div></div>
            <span>
              {{ $t('technology.paper-path.roll-3') }}
            </span>
          </div>
          <div
            v-if="getDataByPrinter.additional"
            class="single-path-control single-path-control--dryer">
            <div class="dryer"></div>
            <span>
              {{ $t('technology.paper-path.dryer') }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <ShareButton
      v-if="getData.shareable_pdf"
      :shareLink="`${cmsURL}assets/${getData.shareable_pdf}`"
      :shareLabel="`${$t('main-nav.technology')} - ${$t('technology.submenu.paper-path')} ${printerName}`"
      :hash="hashify(`${$t('main-nav.technology')} - ${$t('technology.submenu.paper-path')} ${printerName}`)"
      @share-this="shareThis" />
  </div>
</template>

<script>
import axios from 'axios';

import {
  gsap,
} from 'gsap';
import {
  DrawSVGPlugin,
} from 'gsap/DrawSVGPlugin';

import * as endpoints from '@/common/endpoints';
import {
  filters,
  defaultMixins,
} from '@/common/mixins';

import ShareButton from '@/components/ui/ShareButton.vue';

gsap.registerPlugin(DrawSVGPlugin);

export default {
  name: 'PaperPath',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton,
  },
  data() {
    return {
      baseURL: endpoints.BASEURL,
      info: null,
      rollers: 'init',
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/technology_paper_path`, {
      params: {
        fields: '*,translations.*,printer.printers_id',
      },
    })
      .then(({
        data: {
          data,
        },
      }) => {
        this.info = data;
      });
  },
  mounted() {
    this.showMainTabs();
  },
  updated() {
    gsap.timeline()
      .fromTo('.js-animate-path', {
        drawSVG: '0 3%',
      }, {
        duration: 12,
        drawSVG: '97% 100%',
        ease: 'linear',
      })
      .repeat(-1);
  },
  methods: {
    changeImg(state) {
      this.rollers = state;
    },
  },
};

</script>

<style lang="scss" scoped>
  @import '@/scss/views/technology/paperPath.scss';

    .st0 {
      fill: #0097D6;
    }

    .st1 {
      fill: none;
      stroke: #FFFFFF;
      stroke-width: 1.8306;
    }

    .st2 {
      fill: #4296D1;
    }

    .st3 {
      fill: #FFFFFF;
    }

    .st4 {
      fill: #FFFFFF;
      stroke: #4296D1;
      stroke-width: 0.9153;
    }

    .st5 {
      fill: #FFFFFF;
      stroke: #666666;
      stroke-width: 0.9153;
    }

    .st6 {
      fill: #666666;
    }

    .st7 {
      fill: #FFFFFF;
      stroke: #666666;
      stroke-width: 0.9153;
      stroke-miterlimit: 4;
    }

    .st8 {
      opacity: 0.6;
    }

    .st9 {
      fill: #FF33FF;
    }

    .st10 {
      opacity: 0.65;
      fill: #FF33FF;
    }

    .st11 {
      fill: none;
      stroke: #FFFFFF;
      stroke-width: 2.8209;
      stroke-miterlimit: 10;
    }

    .st12 {
      clip-path: url(#SVGID_00000039812029487183317080000003459824412988725654_);
      fill: #0072AD;
    }

    .st13 {
      clip-path: url(#SVGID_00000079444008091351225800000003817133016971815608_);
      fill: #0097D6;
    }

    .st14 {
      clip-path: url(#SVGID_00000177458518897038574700000012400016905867546277_);
      fill: #E5E5E5;
    }

    .st15 {
      fill: none;
      stroke: #0097D6;
      stroke-width: 1.5916;
      stroke-miterlimit: 10;
    }

    .st16 {
      clip-path: url(#SVGID_00000131343993713822471940000010145609625810895541_);
      fill: #0097D6;
    }

    .st17 {
      clip-path: url(#SVGID_00000120547005938759268150000007080076490453595277_);
      fill: #E5E5E5;
    }

    .st18 {
      clip-path: url(#SVGID_00000180344797570199945970000005930105367585992072_);
      fill: #0097D6;
    }

    .st19 {
      clip-path: url(#SVGID_00000158751132690875424490000007663750025167251903_);
      fill: #0097D6;
    }

    .st20 {
      clip-path: url(#SVGID_00000002359726244767221030000013598612750906793391_);
      fill: #FFFFFF;
    }

    .st21 {
      clip-path: url(#SVGID_00000169539641148886019670000013728409972513999490_);
      fill: #0097D6;
    }

    .st22 {
      fill: none;
      stroke: #0097D6;
      stroke-width: 1.4246;
      stroke-miterlimit: 10;
    }

    .st23 {
      clip-path: url(#SVGID_00000158718355042128229900000008831084961201133237_);
      fill: #FFFFFF;
    }

    .st24 {
      clip-path: url(#SVGID_00000103951623030193432350000013745361003291246258_);
      fill: #0097D6;
    }

    .st25 {
      fill: none;
      stroke: #0097D6;
      stroke-width: 2.7057;
      stroke-miterlimit: 10;
    }

    .st26 {
      clip-path: url(#SVGID_00000041261086800187040660000018423214732720664976_);
      fill: #FFFFFF;
    }

    .st27 {
      clip-path: url(#SVGID_00000092425252100023298810000009364208757442274192_);
      fill: #E5E5E5;
    }

    .st28 {
      fill: none;
      stroke: #666666;
      stroke-width: 1.5916;
      stroke-miterlimit: 10;
    }

    .st29 {
      clip-path: url(#SVGID_00000150799858768033303610000015378775439004361125_);
      fill: #666666;
    }

    .st30 {
      clip-path: url(#SVGID_00000008148015710741074960000004381567536139342011_);
      fill: #E5E5E5;
    }

    .st31 {
      clip-path: url(#SVGID_00000093156186898180442670000002083028052983927997_);
      fill: #666666;
    }

    .st32 {
      clip-path: url(#SVGID_00000111880259953256134410000014491283801291050121_);
      fill: #E5E5E5;
    }

    .st33 {
      clip-path: url(#SVGID_00000052784646134048028620000017224342557779648949_);
      fill: #666666;
    }

    .st34 {
      clip-path: url(#SVGID_00000028286133377927315700000018016281251148218500_);
      fill: #E5E5E5;
    }

    .st35 {
      clip-path: url(#SVGID_00000162336554978413155760000011880353822710385823_);
      fill: #666666;
    }

    .st36 {
      clip-path: url(#SVGID_00000122718110395855440190000000028429799855180931_);
      fill: #E5E5E5;
    }

    .st37 {
      clip-path: url(#SVGID_00000077295224715413414020000004095291132713048709_);
      fill: #666666;
    }

    .st38 {
      clip-path: url(#SVGID_00000075151422536254887990000007767680660040339113_);
      fill: #E5E5E5;
    }

    .st39 {
      clip-path: url(#SVGID_00000076566707887115515800000011178679418310765737_);
      fill: #666666;
    }

    .st40 {
      clip-path: url(#SVGID_00000010996180002290579220000000873187627054550202_);
      fill: #E5E5E5;
    }

    .st41 {
      clip-path: url(#SVGID_00000018198013330224296990000005263547653284306091_);
      fill: #666666;
    }

    .st42 {
      clip-path: url(#SVGID_00000046309583629850097100000010920623307067168937_);
      fill: #E5E5E5;
    }

    .st43 {
      clip-path: url(#SVGID_00000020375547595435601830000016506612191521368993_);
      fill: #666666;
    }

    .st44 {
      clip-path: url(#SVGID_00000067222724225503085690000016438667512941694880_);
      fill: #E5E5E5;
    }

    .st45 {
      clip-path: url(#SVGID_00000146479645503861922730000012848343278391734660_);
      fill: #666666;
    }

    .st46 {
      clip-path: url(#SVGID_00000124124388589244858750000016309318249442997691_);
      fill: #E5E5E5;
    }

    .st47 {
      clip-path: url(#SVGID_00000128479991438103584370000015693897958912572328_);
      fill: #666666;
    }

    .st48 {
      clip-path: url(#SVGID_00000096781595157470231160000012869528505771839368_);
      fill: #E5E5E5;
    }

    .st49 {
      fill: none;
      stroke: #666666;
      stroke-width: 1.0469;
      stroke-miterlimit: 10;
    }

    .st50 {
      clip-path: url(#SVGID_00000057124787365965427890000017062433195561374646_);
      fill: #666666;
    }

    .st51 {
      clip-path: url(#SVGID_00000013895991474907878100000018298324085958633111_);
      fill: #E5E5E5;
    }

    .st52 {
      clip-path: url(#SVGID_00000096744511482776620580000016686736722962268807_);
      fill: #666666;
    }

    .st53 {
      clip-path: url(#SVGID_00000003818475175028433170000005234713827152334268_);
      fill: #E5E5E5;
    }

    .st54 {
      clip-path: url(#SVGID_00000035504934185397429160000001463206595858619824_);
      fill: #666666;
    }

    .st55 {
      clip-path: url(#SVGID_00000048490920130169865060000014599628326057248651_);
      fill: #E5E5E5;
    }

    .st56 {
      clip-path: url(#SVGID_00000079470373008283706600000012745076007614083980_);
      fill: #666666;
    }

    .st57 {
      clip-path: url(#SVGID_00000181807584041178591570000013314426782677249685_);
      fill: #E5E5E5;
    }

    .st58 {
      clip-path: url(#SVGID_00000154393437955700452240000013879414428983583417_);
      fill: #666666;
    }

    .st59 {
      clip-path: url(#SVGID_00000054951896082649317630000008739458966836962690_);
      fill: #E5E5E5;
    }

    .st60 {
      clip-path: url(#SVGID_00000154417560059179267310000003449129659614484662_);
      fill: #666666;
    }

    .st61 {
      clip-path: url(#SVGID_00000140725362382450947080000002409986276645439367_);
      fill: #E5E5E5;
    }

    .st62 {
      clip-path: url(#SVGID_00000049933746187832772690000018219339871329833381_);
      fill: #666666;
    }

    .st63 {
      clip-path: url(#SVGID_00000163049594759586245200000002902853485664230810_);
      fill: #E5E5E5;
    }

    .st64 {
      clip-path: url(#SVGID_00000053542614111967485780000014887992917899712641_);
      fill: #666666;
    }

    .st65 {
      clip-path: url(#SVGID_00000012438156437676191000000011644426753111338375_);
      fill: #E5E5E5;
    }

    .st66 {
      clip-path: url(#SVGID_00000088834397899111729240000003526660169300361117_);
      fill: #666666;
    }

    .st67 {
      clip-path: url(#SVGID_00000165934449704610026860000011670883738763344780_);
      fill: #E5E5E5;
    }

    .st68 {
      clip-path: url(#SVGID_00000022551038991039421340000003569238189913644165_);
      fill: #666666;
    }

    .st69 {
      clip-path: url(#SVGID_00000167374195257371563330000001343079762522190240_);
      fill: #E5E5E5;
    }

    .st70 {
      clip-path: url(#SVGID_00000039107984353035350620000018438745271763567036_);
      fill: #666666;
    }

    .st71 {
      clip-path: url(#SVGID_00000128477638233713975460000006870122573457209789_);
      fill: #E5E5E5;
    }

    .st72 {
      clip-path: url(#SVGID_00000127016189748875092260000015985911908945128835_);
      fill: #666666;
    }

    .st73 {
      clip-path: url(#SVGID_00000085225561611871053680000013477329737468635540_);
      fill: #E5E5E5;
    }

    .st74 {
      clip-path: url(#SVGID_00000041268138621679532670000010585761870029275056_);
      fill: #666666;
    }

    .st75 {
      clip-path: url(#SVGID_00000068649525350029700790000006538531047965648784_);
      fill: #E5E5E5;
    }

    .st76 {
      clip-path: url(#SVGID_00000054230073082787434730000010603501837036353954_);
      fill: #666666;
    }

    .st77 {
      clip-path: url(#SVGID_00000120549169833272097820000016556519188692529331_);
      fill: #E5E5E5;
    }

    .st78 {
      clip-path: url(#SVGID_00000066473889345358563970000013885503413247386029_);
      fill: #666666;
    }

    .st79 {
      clip-path: url(#SVGID_00000119809764803877895130000001742215545687677094_);
      fill: #E5E5E5;
    }

    .st80 {
      clip-path: url(#SVGID_00000076590781485053506910000005626424947818157952_);
      fill: #666666;
    }

    .st81 {
      clip-path: url(#SVGID_00000039825667301699492970000001010459368680132006_);
      fill: #E5E5E5;
    }

    .st82 {
      clip-path: url(#SVGID_00000041992390649207908340000016194244188180798361_);
      fill: #666666;
    }

    .st83 {
      clip-path: url(#SVGID_00000057844117790417018580000017467210756657642942_);
      fill: #E5E5E5;
    }

    .st84 {
      clip-path: url(#SVGID_00000120534590989717193520000017542738249185324213_);
      fill: #666666;
    }

    .st85 {
      clip-path: url(#SVGID_00000146458322559415872930000018383336555544229268_);
      fill: #E5E5E5;
    }

    .st86 {
      clip-path: url(#SVGID_00000172411161300596773280000009665098694753365678_);
      fill: #666666;
    }

    .st87 {
      clip-path: url(#SVGID_00000098199997286468679560000018033867605873104529_);
      fill: #E5E5E5;
    }

    .st88 {
      clip-path: url(#SVGID_00000075132498310763627700000016439295909856222879_);
      fill: #666666;
    }

    .st89 {
      clip-path: url(#SVGID_00000039113285116780490530000002078512494257333670_);
      fill: #E5E5E5;
    }

    .st90 {
      clip-path: url(#SVGID_00000090289303154881657130000003919036656879536554_);
      fill: #666666;
    }

    .st91 {
      clip-path: url(#SVGID_00000098208882667567932790000001278786935343136925_);
      fill: #E5E5E5;
    }

    .st92 {
      clip-path: url(#SVGID_00000082329366220913394140000007026277439955670929_);
      fill: #666666;
    }

    .st93 {
      clip-path: url(#SVGID_00000179605552047512205060000003627133317965915782_);
      fill: #E5E5E5;
    }

    .st94 {
      clip-path: url(#SVGID_00000031925214159126857490000011270830469478848139_);
      fill: #666666;
    }

    .st95 {
      clip-path: url(#SVGID_00000137094791646559891900000011926180187889929865_);
      fill: #E5E5E5;
    }

    .st96 {
      clip-path: url(#SVGID_00000166666911138957508120000010403859649086593418_);
      fill: #666666;
    }

    .st97 {
      clip-path: url(#SVGID_00000020378403121509567660000008383947101163241105_);
      fill: #E5E5E5;
    }

    .st98 {
      clip-path: url(#SVGID_00000149343468898744614630000006830907378118807176_);
      fill: #666666;
    }

    .st99 {
      clip-path: url(#SVGID_00000178910747955029905510000010010540494490706618_);
      fill: #E5E5E5;
    }

    .st100 {
      clip-path: url(#SVGID_00000073001374353333351550000000776412170353344919_);
      fill: #666666;
    }

    .st101 {
      clip-path: url(#SVGID_00000103952756210569468480000007873907592022982826_);
      fill: #E5E5E5;
    }

    .st102 {
      clip-path: url(#SVGID_00000060031736570220199990000011231795505622220678_);
      fill: #666666;
    }

    .st103 {
      clip-path: url(#SVGID_00000083082062010699005740000016723553028068421519_);
      fill: #E5E5E5;
    }

    .st104 {
      clip-path: url(#SVGID_00000021088532630994656650000008251316425930791045_);
      fill: #666666;
    }

    .st105 {
      clip-path: url(#SVGID_00000036243869602950844120000010079737495134091175_);
      fill: #E5E5E5;
    }

    .st106 {
      clip-path: url(#SVGID_00000068673274991574108250000013011788122257882798_);
      fill: #666666;
    }

    .st107 {
      clip-path: url(#SVGID_00000147922460035340854150000013729933446328024252_);
      fill: #E5E5E5;
    }

    .st108 {
      clip-path: url(#SVGID_00000183945644249761998510000017097089524951379135_);
      fill: #666666;
    }

    .st109 {
      clip-path: url(#SVGID_00000167367187065290088890000004014310296192427938_);
      fill: #E5E5E5;
    }

    .st110 {
      clip-path: url(#SVGID_00000078006594723266255760000002108691313408344243_);
      fill: #666666;
    }

    .st111 {
      clip-path: url(#SVGID_00000063598889778057276180000015153680138937687942_);
      fill: #E5E5E5;
    }

    .st112 {
      clip-path: url(#SVGID_00000020368981520587046210000016463985311323533729_);
      fill: #666666;
    }

    .st113 {
      clip-path: url(#SVGID_00000121248302448217431250000004063970050098578056_);
      fill: #E5E5E5;
    }

    .st114 {
      clip-path: url(#SVGID_00000080185290721119537860000017080502362866546332_);
      fill: #666666;
    }

    .st115 {
      clip-path: url(#SVGID_00000050644037069001336260000004396716891135821718_);
      fill: #E5E5E5;
    }

    .st116 {
      clip-path: url(#SVGID_00000080893294742929594470000009306793637057093258_);
      fill: #666666;
    }

    .st117 {
      clip-path: url(#SVGID_00000114047008080499673510000008913133010146098346_);
      fill: #E5E5E5;
    }

    .st118 {
      clip-path: url(#SVGID_00000051344979802779657080000013035243717347621552_);
      fill: #666666;
    }

    .st119 {
      clip-path: url(#SVGID_00000163037588917332212250000015214140176733434292_);
      fill: #E5E5E5;
    }

    .st120 {
      clip-path: url(#SVGID_00000174600602731366036900000016564239158695211152_);
      fill: #666666;
    }

    .st121 {
      clip-path: url(#SVGID_00000064313369107894018470000017977916199310848927_);
      fill: #E5E5E5;
    }

    .st122 {
      clip-path: url(#SVGID_00000092441178975883932510000015881296093249769622_);
      fill: #666666;
    }

    .st123 {
      clip-path: url(#SVGID_00000009574178837363666820000005997380091858722730_);
      fill: #E5E5E5;
    }

    .st124 {
      clip-path: url(#SVGID_00000051347524983016593120000014922738233637062022_);
      fill: #666666;
    }

    .st125 {
      clip-path: url(#SVGID_00000008860574348390297660000012699202241636945821_);
      fill: #E5E5E5;
    }

    .st126 {
      clip-path: url(#SVGID_00000009586579859365273170000002437943472462570410_);
      fill: #666666;
    }

    .st127 {
      clip-path: url(#SVGID_00000139282779110095744620000003788426115302497408_);
      fill: #E5E5E5;
    }

    .st128 {
      clip-path: url(#SVGID_00000044890427192120715090000015597795543781831559_);
      fill: #666666;
    }

    .st129 {
      clip-path: url(#SVGID_00000042000432687134686200000004151427253117300118_);
      fill: #E5E5E5;
    }

    .st130 {
      clip-path: url(#SVGID_00000121976482635972448710000001012544269132775065_);
      fill: #666666;
    }

    .st131 {
      clip-path: url(#SVGID_00000076562817843635940310000008657560185437929127_);
      fill: #E5E5E5;
    }

    .st132 {
      clip-path: url(#SVGID_00000102541725135135668270000014601476968960942486_);
      fill: #666666;
    }

    .st133 {
      clip-path: url(#SVGID_00000124864700653627093130000003557199405933821588_);
      fill: #E5E5E5;
    }

    .st134 {
      clip-path: url(#SVGID_00000091725568646095312400000002730450876152175490_);
      fill: #666666;
    }

    .st135 {
      clip-path: url(#SVGID_00000016054988150305467780000008277491947681213094_);
      fill: #E5E5E5;
    }

    .st136 {
      clip-path: url(#SVGID_00000122714303608464382580000008214515932511674019_);
      fill: #666666;
    }

    .st137 {
      clip-path: url(#SVGID_00000077312786311122546000000006730698001312855944_);
      fill: #E5E5E5;
    }

    .st138 {
      clip-path: url(#SVGID_00000087413932246353992840000007238202295523339144_);
      fill: #666666;
    }

    .st139 {
      clip-path: url(#SVGID_00000008150736630775166250000006121393046822462612_);
      fill: #E5E5E5;
    }

    .st140 {
      clip-path: url(#SVGID_00000057132548027488452650000018011993155358719389_);
      fill: #666666;
    }

    .st141 {
      clip-path: url(#SVGID_00000070839630685099708840000016103130127269503893_);
      fill: #E5E5E5;
    }

    .st142 {
      clip-path: url(#SVGID_00000141435118499853390740000009165054436286925495_);
      fill: #666666;
    }

    .st143 {
      clip-path: url(#SVGID_00000114040363103150249800000006753683209004739249_);
      fill: #E5E5E5;
    }

    .st144 {
      clip-path: url(#SVGID_00000091736024538840884090000012823034295491813048_);
      fill: #666666;
    }

    .st145 {
      clip-path: url(#SVGID_00000052820612001872064090000001280722128420587199_);
      fill: #E5E5E5;
    }

    .st146 {
      fill: none;
      stroke: #666666;
      stroke-width: 1.9099;
      stroke-miterlimit: 10;
    }

    .st147 {
      clip-path: url(#SVGID_00000168801488306193712210000010838594764538045363_);
      fill: #666666;
    }

    .st148 {
      clip-path: url(#SVGID_00000111150735732069877160000008620827049331506862_);
      fill: #E5E5E5;
    }

    .st149 {
      clip-path: url(#SVGID_00000008125931932073486660000003521739808705425041_);
      fill: #666666;
    }

    .st150 {
      clip-path: url(#SVGID_00000047060310930373485660000009601986387244685966_);
      fill: #E5E5E5;
    }

    .st151 {
      fill: none;
      stroke: #666666;
      stroke-width: 1.2733;
      stroke-miterlimit: 10;
    }

    .st152 {
      clip-path: url(#SVGID_00000067938376791850581870000010687990322307879075_);
      fill: #666666;
    }

    .st153 {
      clip-path: url(#SVGID_00000139275936771444509930000005840104837323721353_);
      fill: #E5E5E5;
    }

    .st154 {
      clip-path: url(#SVGID_00000092443452200455440530000015391599068975967416_);
      fill: #666666;
    }

    .st155 {
      clip-path: url(#SVGID_00000021091296770559941210000003191940168508846003_);
      fill: #E5E5E5;
    }

    .st156 {
      clip-path: url(#SVGID_00000020378529779677946220000013940360675542214019_);
      fill: #666666;
    }

    .st157 {
      clip-path: url(#SVGID_00000039814088570060095650000013875070969063706802_);
      fill: #E5E5E5;
    }

    .st158 {
      clip-path: url(#SVGID_00000163038042275749204310000014008486778615776185_);
      fill: #666666;
    }

    .st159 {
      clip-path: url(#SVGID_00000054972283698817085460000010889246836642468235_);
      fill: #E5E5E5;
    }

    .st160 {
      clip-path: url(#SVGID_00000083048561615444999340000015549276676293981857_);
      fill: #666666;
    }

    .st161 {
      clip-path: url(#SVGID_00000134228169984359286940000007626758946015468192_);
      fill: #E5E5E5;
    }

    .st162 {
      clip-path: url(#SVGID_00000087396387310737763450000006536884886176377728_);
      fill: #666666;
    }

    .st163 {
      clip-path: url(#SVGID_00000145028842418380609830000011209327221780299428_);
      fill: #E5E5E5;
    }

    .st164 {
      fill: none;
      stroke: #666666;
      stroke-width: 0.7052;
      stroke-miterlimit: 10;
    }

    .st165 {
      clip-path: url(#SVGID_00000057123357620273851750000001027199421220510886_);
      fill: #666666;
    }

    .st166 {
      filter: url(#Adobe_OpacityMaskFilter);
    }

    .st167 {
      clip-path: url(#SVGID_00000139272509957493408890000005505316353054196122_);
    }

    .st168 {
      opacity: 0.5;
      clip-path: url(#SVGID_00000139272509957493408890000005505316353054196122_);
      mask: url(#SVGID_00000010290978784757704000000015480877996899077045_);
    }

    .st169 {
      filter: url(#Adobe_OpacityMaskFilter_00000043435730775686373260000004017974570818749358_);
    }

    .st170 {
      enable-background: new;
    }

    .st171 {
      clip-path: url(#SVGID_00000079467208785462781140000013553488233948852626_);
    }

    .st172 {
      opacity: 0.5;
    }

    .st173 {
      clip-path: url(#SVGID_00000072966969742527061830000003814600731749428657_);
    }

    .st174 {
      filter: url(#Adobe_OpacityMaskFilter_00000112619606483321198960000016570652056981050797_);
    }

    .st175 {
      clip-path: url(#SVGID_00000082337859162795422820000016404355198226996356_);
    }

    .st176 {
      filter: url(#Adobe_OpacityMaskFilter_00000114753371427785617770000000889167197781602474_);
    }

    .st177 {
      clip-path: url(#SVGID_00000077291740995522050780000000315935984261802928_);
    }

    .st178 {
      opacity: 0.5;
      clip-path: url(#SVGID_00000077291740995522050780000000315935984261802928_);
      mask: url(#SVGID_00000076561206777812132940000016524169172662881442_);
    }

    .st179 {
      filter: url(#Adobe_OpacityMaskFilter_00000083064869339722377610000005786828887117006989_);
    }

    .st180 {
      clip-path: url(#SVGID_00000031189156375721794290000014927654546891510196_);
    }

    .st181 {
      clip-path: url(#SVGID_00000124151437434704874660000002523939031909065856_);
    }

    .st182 {
      filter: url(#Adobe_OpacityMaskFilter_00000146464727626274650580000004645511477556363686_);
    }

    .st183 {
      clip-path: url(#SVGID_00000018916951116867999030000004122397546536733065_);
    }

    .st184 {
      filter: url(#Adobe_OpacityMaskFilter_00000101069860250181268380000009664212002031459468_);
    }

    .st185 {
      clip-path: url(#SVGID_00000050658736890459170930000000794495132916986548_);
    }

    .st186 {
      opacity: 0.5;
      clip-path: url(#SVGID_00000050658736890459170930000000794495132916986548_);
      mask: url(#SVGID_00000026844520199505931940000000267991420237673383_);
    }

    .st187 {
      filter: url(#Adobe_OpacityMaskFilter_00000001654747217626398810000000469028005277685901_);
    }

    .st188 {
      clip-path: url(#SVGID_00000062169424659010414790000015783625025317307307_);
    }

    .st189 {
      clip-path: url(#SVGID_00000127038513935784516370000012838046980163919775_);
    }

    .st190 {
      filter: url(#Adobe_OpacityMaskFilter_00000085940712868603071850000004990884644005729194_);
    }

    .st191 {
      clip-path: url(#SVGID_00000080888014814680945440000011093603684322567054_);
    }

    .st192 {
      clip-path: url(#SVGID_00000016069009326257288350000007809726066782587049_);
      fill: #0097D6;
    }

    .st193 {
      clip-path: url(#SVGID_00000013165318741182159120000010708034861895372180_);
      fill: #0097D6;
    }

    .st194 {
      display: none;
    }

    .st195 {
      display: inline;
      fill: none;
      stroke: #FFFFFF;
      stroke-width: 2.5475;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .st196 {
      display: inline;
    }

    .st197 {
      clip-path: url(#SVGID_00000065048643690707813900000010411930532807818126_);
      fill: #0097D6;
    }

    .st198 {
      fill: none;
      stroke: #0097D6;
      stroke-width: 2.1311;
      stroke-miterlimit: 10;
    }

    .st199 {
      clip-path: url(#SVGID_00000163756482968491410000000016641245227822054829_);
      fill: #FFFFFF;
    }

    .st200 {
      clip-path: url(#SVGID_00000025407766958226457210000013126932235820789689_);
      fill: #0097D6;
    }

    .st201 {
      clip-path: url(#SVGID_00000065789353278749670090000002458750373315282566_);
      fill: #FFFFFF;
    }

    .st202 {
      clip-path: url(#SVGID_00000134242375722311084620000008195253133138396551_);
      fill: #0097D6;
    }

    .st203 {
      clip-path: url(#SVGID_00000036951250233564820070000007058100180144752543_);
      fill: #FFFFFF;
    }

    .st204 {
      clip-path: url(#SVGID_00000147899166073095899590000017638287359740763303_);
      fill: #0097D6;
    }

    .st205 {
      clip-path: url(#SVGID_00000159444565115762304970000000172795879417893771_);
      fill: #FFFFFF;
    }

    .st206 {
      clip-path: url(#SVGID_00000169549862204810274030000013310640179440122006_);
      fill: #0097D6;
    }

    .st207 {
      clip-path: url(#SVGID_00000059310171513126770110000014292083134305956226_);
      fill: #FFFFFF;
    }

    .st208 {
      clip-path: url(#SVGID_00000068634877721020483910000005835459643998023566_);
      fill: #0097D6;
    }

    .st209 {
      clip-path: url(#SVGID_00000036218457253246818600000005410919292613178550_);
      fill: #FFFFFF;
    }

    .st210 {
      clip-path: url(#SVGID_00000114043185188633070250000007708456594977524137_);
      fill: #0097D6;
    }

    .st211 {
      clip-path: url(#SVGID_00000058587268608790184520000009344008843744200595_);
      fill: #FFFFFF;
    }

    .st212 {
      clip-path: url(#SVGID_00000104688622343325857110000015196658097644566152_);
      fill: #E5E5E5;
    }

    .st213 {
      fill: none;
      stroke: #0097D6;
      stroke-width: 0.7052;
      stroke-miterlimit: 10;
    }

    .st214 {
      clip-path: url(#SVGID_00000109750428209413182830000012443565559653559445_);
      fill: #0097D6;
    }

    .st215 {
      clip-path: url(#SVGID_00000001644706275259631590000015941510453832335532_);
      fill: #E5E5E5;
    }

    .st216 {
      clip-path: url(#SVGID_00000042006045263110756820000011358219750715694781_);
      fill: #0097D6;
    }

    .st217 {
      clip-path: url(#SVGID_00000088131491856449290800000015952267580780516249_);
      fill: #E5E5E5;
    }

    .st218 {
      clip-path: url(#SVGID_00000090292939518093787270000015203543222140980663_);
      fill: #0097D6;
    }

    .st219 {
      clip-path: url(#SVGID_00000116922251191580192350000002102242201728832697_);
      fill: #E5E5E5;
    }

    .st220 {
      clip-path: url(#SVGID_00000000200585823130549620000013023836138855142304_);
      fill: #0097D6;
    }

    .st221 {
      clip-path: url(#SVGID_00000148657302293847019400000016814991901925928845_);
      fill: #E5E5E5;
    }

    .st222 {
      clip-path: url(#SVGID_00000164497239752428504160000014212340178632782232_);
      fill: #0097D6;
    }

    .st223 {
      clip-path: url(#SVGID_00000106863566168890450990000006351384380171214725_);
      fill: #E5E5E5;
    }

    .st224 {
      clip-path: url(#SVGID_00000002379508559061974720000016001132459948623776_);
      fill: #0097D6;
    }

    .st225 {
      clip-path: url(#SVGID_00000032624009750929861430000004117846485060348589_);
      fill: #E5E5E5;
    }

    .st226 {
      clip-path: url(#SVGID_00000116199853258874353250000005685725566917980861_);
      fill: #0097D6;
    }

    .st227 {
      clip-path: url(#SVGID_00000029004761375072671960000001166666814102831274_);
      fill: #E5E5E5;
    }

    .st228 {
      clip-path: url(#SVGID_00000059272101748875890990000004693681858725352119_);
      fill: #0097D6;
    }

    .st229 {
      clip-path: url(#SVGID_00000105420952782116663850000005690213794330678964_);
      fill: #E5E5E5;
    }

    .st230 {
      clip-path: url(#SVGID_00000145773677546283733350000002958675890944741527_);
      fill: #0097D6;
    }

    .st231 {
      clip-path: url(#SVGID_00000005965224517162190660000006722730717215690627_);
      fill: #E5E5E5;
    }

    .st232 {
      clip-path: url(#SVGID_00000139269822379860367420000003435420505094287037_);
      fill: #0097D6;
    }

    .st233 {
      clip-path: url(#SVGID_00000083792741449201847830000014216194086569597599_);
      fill: #E5E5E5;
    }

    .st234 {
      clip-path: url(#SVGID_00000101083969423159445140000012076570144410714022_);
      fill: #0097D6;
    }

    .st235 {
      clip-path: url(#SVGID_00000159457763885456209800000016132859352037408403_);
      fill: #E5E5E5;
    }

    .st236 {
      clip-path: url(#SVGID_00000159434972820945558880000002537015619728400787_);
      fill: #0097D6;
    }

    .st237 {
      clip-path: url(#SVGID_00000036239760286646149520000003973037332531883924_);
      fill: #E5E5E5;
    }

    .st238 {
      clip-path: url(#SVGID_00000096019581431051892180000017389705042182731944_);
      fill: #0097D6;
    }

    .st239 {
      clip-path: url(#SVGID_00000109714915334319409910000016072621489075978917_);
      fill: #E5E5E5;
    }

    .st240 {
      clip-path: url(#SVGID_00000143589062072209282680000005866402566405479061_);
      fill: #0097D6;
    }

    .st241 {
      clip-path: url(#SVGID_00000112626456900622234720000016308206944171752639_);
      fill: #E5E5E5;
    }

    .st242 {
      clip-path: url(#SVGID_00000114055269895194423950000001575296677151987895_);
      fill: #0097D6;
    }

    .st243 {
      clip-path: url(#SVGID_00000103231269759954470080000012917937663469172146_);
      fill: #E5E5E5;
    }

    .st244 {
      clip-path: url(#SVGID_00000109017631210270315180000001250256256026787484_);
      fill: #0097D6;
    }

    .st245 {
      clip-path: url(#SVGID_00000096775058253959465280000012288209105586695592_);
      fill: #E5E5E5;
    }

    .st246 {
      clip-path: url(#SVGID_00000023242455031115844480000015592033100315150247_);
      fill: #0097D6;
    }

    .st247 {
      clip-path: url(#SVGID_00000042708133050281804610000012097208893969462933_);
      fill: #E5E5E5;
    }

    .st248 {
      clip-path: url(#SVGID_00000159454284369716306950000005255399558017728646_);
      fill: #0097D6;
    }

    .st249 {
      clip-path: url(#SVGID_00000133513509448737495320000006981017643293380539_);
      fill: #E5E5E5;
    }

    .st250 {
      clip-path: url(#SVGID_00000096767428367823129200000014879224922187545758_);
      fill: #0097D6;
    }

    .st251 {
      clip-path: url(#SVGID_00000025438079541582280700000002118074031257768833_);
      fill: #E5E5E5;
    }

    .st252 {
      clip-path: url(#SVGID_00000065757651402688392150000008542139868440796569_);
      fill: #0097D6;
    }

    .st253 {
      clip-path: url(#SVGID_00000103267360930903004880000007312557085541675199_);
      fill: #E5E5E5;
    }

    .st254 {
      clip-path: url(#SVGID_00000012444939764307254140000012537466227166671761_);
      fill: #0097D6;
    }

    .st255 {
      clip-path: url(#SVGID_00000158749259518748651920000012982077132655078552_);
      fill: #E5E5E5;
    }

    .st256 {
      clip-path: url(#SVGID_00000037693732300143483100000009629261505757719464_);
      fill: #0097D6;
    }

    .st257 {
      clip-path: url(#SVGID_00000092425857866924163810000015253701208470383755_);
      fill: #0097D6;
    }

    .st258 {
      clip-path: url(#SVGID_00000063627834720908505330000003190281992643303574_);
      fill: #0097D6;
    }
</style>
