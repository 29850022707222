<template>
  <Modal
    class="modal--login js-modal-login"
    idModal="loginModal"
    id="loginModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="loginModalLabel"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
    ref="loginModal">
    <!-- eslint-disable max-len -->

    <!-- Initial form -->
    <div class="wrapper-form login-part text-center" v-show="currentForm !== 'start'">
      <div class="logo">
        <img src="images/hp-blueprint-logo.svg" alt="">
      </div>

      <!-- Login form -->
      <LoginForm v-if="currentForm === 'login'"
        @success="changeForm('start')"/>
      <!-- // Login form -->

      <!-- Create Account form -->
      <CreateAccount v-if="currentForm === 'create'"/>
      <!-- // Create Account form -->

      <!-- Forgot Password form -->
      <ResetPassword  v-if="currentForm === 'password'" />
      <!-- // Forgot Password form -->

      <div class="login-actions">
        <a
          href="javascript:void(0);"
          v-show="currentForm !== 'login'"
          @click="changeForm('login')">
          {{ $t('login.common.login') }}
        </a>
        <a
          href="javascript:void(0);"
          v-show="currentForm !== 'password'"
          @click="changeForm('password')">
          {{ $t('login.common.forgot-password') }}
        </a>
        <a
          href="javascript:void(0);"
          v-show="currentForm !== 'create'"
          @click="changeForm('create')">
          {{ $t('login.common.new-account') }}
        </a>
      </div>
    </div>
    <!-- // Initial form -->

    <!-- New Presentation form -->
    <div class="wrapper-form presentation-part text-center" v-show="currentForm === 'start'">
      <div class="text-center">
        <h2 class="title">
          {{ $t('login.new-presentation.headline') }}
        </h2>
        <p>
          {{ $t('login.new-presentation.intro') }}
        </p>

        <a
          aria-label="Skip"
          class="cta cta--white skip-but ra-gtm-event"
          data-dismiss="modal"
          data-gtm-section="Presentation Login"
          data-gtm-label="Skip"
          href="javascript:void(0)"
          @click="$emit('skip')">
          {{ $t('buttons.skip') }}
        </a>
      </div>

      <NewPresentation @close-modal="closeModal" />
    </div>
    <!-- // New Presentation form -->
  </Modal>
</template>

<script>
import $ from 'jquery';
// import axios from 'axios';

import { defaultMixins } from '@/common/mixins';
import basil from '@/common/cookies';
import Modal from '@/components/modal/Modal.vue';
// import getAllParameters from '@/common/functions';
// import * as endpoints from '@/common/endpoints';

import LoginForm from '@/views/login/LoginForm.vue';
import CreateAccount from '@/views/login/CreateAccount.vue';
import ResetPassword from '@/views/login/ResetPassword.vue';
import NewPresentation from '@/views/login/NewPresentation.vue';

export default {
  name: 'Login',
  mixins: [defaultMixins],
  props: {
    currentForm: String,
  },
  emits: ['change-form', 'skip', 'start-presentation'],
  components: {
    Modal,
    LoginForm,
    CreateAccount,
    ResetPassword,
    NewPresentation,
  },
  mounted() {
    // const urlParams = getAllParameters();
    // const mytoken = urlParams.token;
    // If user is already logged
    console.log(basil.get('user'));
    if (basil.get('user')) {
      this.closeModal();
      this.$store.commit('fillSalesPersonData', basil.get('user'));
      if (basil.get('currentPrinter')) {
        this.$store.commit('changePrinter', basil.get('currentPrinter'));
      }
      this.$store.commit('login', 1);

      // Set locale
      basil.set('locale', basil.get('user').language);
      this.$i18n.locale = basil.get('user').language;

      // Trigger tracking function
      this.insertTrackingtoHPDB('all', '', 'login');
    } else {
      // REMOVE THIS (For testing only)
      $('.js-modal-login').modal('show');
      // END REMOVE THIS

      // If no cookie, run authorization
      // axios.post(
      //   `${endpoints.BASEAPIURL}authorization${endpoints.EXT}`,
      //   {
      //     token: mytoken,
      //   },
      // )
      //   .then((data) => {
      //     const json = data.data;
      //     if (json) {
      //       const sPerson = {
      //         email: json.email,
      //         firstname: json.first_name,
      //         lastname: json.last_name,
      //         phone: json.mobile_number,
      //         region: json.region,
      //       };

      //       axios.post(
      //         `${endpoints.BASEAPIURL}getusernew${endpoints.EXT}`,
      //         {
      //           ...sPerson,
      //         },
      //       )
      //         .then((response) => {
      //           const jsonuser = response.data;
      //           console.log(response.data);
      //           if (jsonuser.reply === 1) {
      //             if (jsonuser.status !== 3) {
      //               // Set remaining properties of the user
      //               sPerson.photo = jsonuser.photo;
      //               sPerson.team = jsonuser.team;
      //               sPerson.title = jsonuser.title;
      //               sPerson.language = jsonuser.language;
      //               console.info(sPerson);

      //               // Set cookies
      //               basil.set('user', { ...sPerson });
      //               const localeToSet = (!jsonuser.language) ? 'en' : jsonuser.language;
      //               basil.set('locale', localeToSet);
      //               this.$i18n.locale = localeToSet;

      //               this.closeModal();
      //               this.$store.commit('fillSalesPersonData', sPerson);
      //               this.$store.commit('login', 1);

      //               this.insertTrackingtoHPDB('all', '', 'login');
      //             } else {
      //               // There is no user:
      //               axios.post(
      //                 `${endpoints.BASEAPIURL}createuser${endpoints.EXT}`,
      //                 {
      //                   action: 'newuser',
      //                   ...sPerson,
      //                 },
      //               )
      //                 .then((createres) => {
      //                   console.log(createres);
      //                   const createjson = createres.data;
      //                   this.isLoading = false;

      //                   if (createjson.reply === 1) {
      //                     if (createjson.status === 2) { // User already exists
      //                       this.successMsg = 'exists';
      //                     } else { // New "admin" user
      //                       this.successMsg = 'new';
      //                       sPerson.photo = 'https://pagewide.hp-blueprint.com/images/headshot.jpg';
      //                       sPerson.team = [];
      //                       sPerson.title = '';
      //                       sPerson.language = 'en';
      //                     }

      //                     // Set cookies
      //                     basil.set('user', { ...sPerson });
      //                     const localeToSet = 'en';
      //                     basil.set('locale', localeToSet);
      //                     this.$i18n.locale = localeToSet;

      //                     this.closeModal();
      //                     this.$store.commit('fillSalesPersonData', sPerson);
      //                     this.$store.commit('login', 1);

      //                     this.insertTrackingtoHPDB('all', '', 'login');
      //                   } else {
      //                     this.successMsg = 'error';
      //                   }
      //                 })
      //                 .catch((error) => {
      //                   console.error(error);
      //                 });
      //               console.log('create the user');
      //             }
      //           } else {
      //             console.log(`Error: ${jsonuser.message}`);
      //           }
      //         })
      //         .catch((error) => {
      //           console.error(error);
      //         });
      //     } else {
      //       console.error('HP authorization failed');
      //       this.$store.commit('login', 0);
      //       window.location.href = 'https://hp-blueprint.com';
      //     }
      //   })
      //   .catch(() => {
      //     console.error('Error: not authorized');
      //     this.$store.commit('login', 0);
      //     window.location.href = 'https://hp-blueprint.com';
      //   });
    }
  },
  methods: {
    changeForm(payload) {
      this.$emit('change-form', payload);
    },
    closeModal() {
      this.$emit('start-presentation');
      $('.js-modal-login').modal('hide');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/login.scss';
</style>
