import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { createApp } from 'vue';

import App from './App.vue';

import router from './router';
import store from './store/store';
import i18n from './i18n';

const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.mount('#app');
