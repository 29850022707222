<template>
  <div>
   <div class="why-digital-reasons">
    <div class="why-digital-reasons__single why-digital-reasons__single--first">
      <h4 class="title title--small title--blue">
        The Market Demand
      </h4>
      <p>
        Produce more for less, produce it faster & make it more sustainable.
      </p>
    </div>
    <div class="why-digital-reasons__single">
      <h4 class="title title--small title--blue">
        The Converter Goal
      </h4>
      <p>
        Sell more corrugated packaging, increase profits, & reduce business risk.
      </p>
    </div>
    <div class="why-digital-reasons__single">
      <h4 class="title title--small title--blue">
        Digital Printing
      </h4>
      <p>
        The bridge connecting market demand with converter goals.
      </p>
    </div>
   </div>

   <div class="why-digital-benefits">
    <h4 class="title title--medium">
      Use digital print to produce corrugated packaging at a lower cost, faster, and better
    </h4>
    <div class="row">
      <div class="col-md-6 col-xl-3">
        <div class="why-digital-benefits__single">
          <div class="why-digital-benefits__single-img">
            <img src="images/why-digital/digital-value/why-digital-1.png?v=1" alt="">
          </div>
          <div class="why-digital-benefits__single-btn text-center">
            <a class="cta ra-gtm-event"
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#digitalPrint-1"
              data-gtm-section="Why Digital"
              data-gtm-subsection="Digital Value Proposition"
              data-gtm-label="Click New Presentation">
              Lower Cost
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="why-digital-benefits__single">
          <div class="why-digital-benefits__single-img">
            <img src="images/why-digital/digital-value/why-digital-2.png?v=1" alt="">
          </div>
          <div class="why-digital-benefits__single-btn text-center">
            <a class="cta ra-gtm-event"
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#digitalPrint-2"
              data-gtm-section="Why Digital"
              data-gtm-subsection="Digital Value Proposition"
              data-gtm-label="Click New Presentation">
              Faster
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="why-digital-benefits__single">
          <div class="why-digital-benefits__single-img">
            <img src="images/why-digital/digital-value/why-digital-3.png?v=1" alt="">
          </div>
          <div class="why-digital-benefits__single-btn text-center">
            <a class="cta ra-gtm-event"
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#digitalPrint-3"
              data-gtm-section="Why Digital"
              data-gtm-subsection="Digital Value Proposition"
              data-gtm-label="Click New Presentation">
              Better
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="why-digital-benefits__single">
          <div class="why-digital-benefits__single-img">
            <img src="images/why-digital/digital-value/why-digital-4.png?v=1" alt="">
          </div>
          <div class="why-digital-benefits__single-btn text-center">
            <a class="cta ra-gtm-event"
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#digitalPrint-4"
              data-gtm-section="Why Digital"
              data-gtm-subsection="Digital Value Proposition"
              data-gtm-label="Click New Presentation">
              TV Box Use Case
            </a>
          </div>
        </div>
      </div>
    </div>
   </div>

   <Modal1 />
   <Modal2 />
   <Modal3 />
   <Modal4 />
  </div>
</template>

<script>
import Modal1 from '@/views/why-digital/digital-value/why-digital-print/Modal1.vue';
import Modal2 from '@/views/why-digital/digital-value/why-digital-print/Modal2.vue';
import Modal3 from '@/views/why-digital/digital-value/why-digital-print/Modal3.vue';
import Modal4 from '@/views/why-digital/digital-value/why-digital-print/Modal4.vue';

export default {
  name: 'WhyDigitalPrint',
  components: {
    Modal1,
    Modal2,
    Modal3,
    Modal4,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/why-digital/digital-value/why-digital-print';
</style>
