<template>
  <SubsectionNavBottom
    class="section--top technology"
    parent="Technology"
    :sidebar="sidebar"
    :subSections="subSections">
    <router-view></router-view>
  </SubsectionNavBottom>
</template>

<script>
import { defaultMixins, filters } from '@/common/mixins';

import SubsectionNavBottom from '@/layout/SubsectionNavBottom.vue';

export default {
  name: 'Technology',
  mixins: [defaultMixins, filters],
  props: {
    sidebar: Boolean,
  },
  components: {
    SubsectionNavBottom,
  },
  computed: {
    subSections() {
      const SubSectionsMapping = {
        1: [
          {
            label: this.$i18n.t('technology.submenu.tech-specs'),
            shareLabel: 'Technical Specs',
            url: '',
          },
          {
            label: this.$i18n.t('technology.submenu.hp-tij'),
            shareLabel: 'Hp TIJ',
            url: 'hp-tij',
          },
          {
            label: this.$i18n.t('technology.submenu.print-modes'),
            shareLabel: 'Print Modes',
            url: 'print-modes',
          },
          {
            label: this.$i18n.t('technology.submenu.ink'),
            shareLabel: 'Ink',
            url: 'Ink',
          },
          {
            label: this.$i18n.t('technology.submenu.paper-path'),
            shareLabel: 'Paper Path',
            url: 'paper-path',
          },
        ],
        2: [
          {
            label: this.$i18n.t('technology.submenu.tech-specs'),
            shareLabel: 'Technical Specs',
            url: '',
          },
          {
            label: this.$i18n.t('technology.submenu.hp-tij'),
            shareLabel: 'Hp TIJ',
            url: 'hp-tij',
          },
          {
            label: this.$i18n.t('technology.submenu.print-modes'),
            shareLabel: 'Print Modes',
            url: 'print-modes',
          },
          {
            label: this.$i18n.t('technology.submenu.ink'),
            shareLabel: 'Ink',
            url: 'Ink',
          },
          {
            label: this.$i18n.t('technology.submenu.paper-path'),
            shareLabel: 'Paper Path',
            url: 'paper-path',
          },
        ],
        3: [
          {
            label: this.$i18n.t('technology.submenu.tech-specs'),
            shareLabel: 'Technical Specs',
            url: '',
          },
          {
            label: this.$i18n.t('technology.submenu.hp-tij'),
            shareLabel: 'Hp TIJ',
            url: 'hp-tij',
          },
          {
            label: this.$i18n.t('technology.submenu.print-modes'),
            shareLabel: 'Print Modes',
            url: 'print-modes',
          },
          {
            label: this.$i18n.t('technology.submenu.ink'),
            shareLabel: 'Ink',
            url: 'Ink',
          },
          {
            label: this.$i18n.t('technology.submenu.paper-path'),
            shareLabel: 'Paper Path',
            url: 'paper-path',
          },
        ],
      };

      return SubSectionsMapping[this.currentPrinter];
    },
  },
};
</script>
