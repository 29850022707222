<template>
  <div>
    <!-- eslint-disable max-len -->
    <form id="create-account">
      <p>
        {{ $t("login.create-account.intro") }}
      </p>
      <div class="form-group text-left">
        <label for="newFirstName">
          {{ $t("login.create-account.first-name") }}*
        </label>
        <input
          class="form-control"
          id="newFirstName"
          name="newFirstName"
          :placeholder="$t('login.common.placeholder.firstname')"
          type="text"
          required
          v-model="formData.firstname">
      </div>
      <div class="form-group text-left">
        <label for="newLastName">
          {{ $t("login.create-account.last-name") }}*
        </label>
        <input
          class="form-control"
          id="newLastName"
          name="newLastName"
          :placeholder="$t('login.common.placeholder.lastname')"
          type="text"
          required
          v-model="formData.lastname">
      </div>
      <div class="form-group text-left">
        <label for="newTitle">
          {{ $t("login.create-account.title") }}*
        </label>
        <input
          class="form-control"
          id="newTitle"
          name="newTitle"
          :placeholder="$t('login.common.placeholder.title')"
          type="text"
          required
          v-model="formData.title">
      </div>
      <div class="form-group text-left">
        <label for="newEmail">
          {{ $t("login.create-account.email") }}*
        </label>
        <input
          class="form-control"
          id="newEmail"
          name="newEmail"
          :placeholder="$t('login.common.placeholder.email')"
          type="email"
          required
          v-model="formData.email">
      </div>
      <div class="form-group text-left">
        <label for="newRegion">
          {{ $t("login.create-account.region.label") }}*
        </label>
        <select
          class="form-control"
          name="newRegion"
          id="newRegion"
          required
          v-model="formData.region">
          <option value="" selected disabled>
            {{ $t("login.create-account.region.label-select") }}
          </option>
          <option value="AMS">
            {{ $t("login.create-account.region.ams") }}
          </option>
          <option value="APJ">
            {{ $t("login.create-account.region.apj") }}
          </option>
          <option value="EMEA">
            {{ $t("login.create-account.region.emea") }}
          </option>
        </select>
      </div>
      <div class="form-group text-left">
        <label for="newLang">
          {{ $t("login.create-account.language.label") }}*
        </label>
        <select
          class="form-control"
          name="newLang"
          id="newLang"
          required
          v-model="formData.lang">
          <option value="" selected disabled>
            {{ $t("login.create-account.language.label-select") }}
          </option>
          <option value="en">
            {{ $t("login.create-account.language.en") }}
          </option>
          <option value="de">
            {{ $t("login.create-account.language.de") }}
          </option>
          <option value="fr">
            {{ $t("login.create-account.language.fr") }}
          </option>
        </select>
      </div>
      <button
        class="cta mx-auto my-2"
        type="submit"
        @click="submitForm">
        <template v-if="isLoading">
          {{ $t("common.actions.creating") }}&nbsp;
          <i class="fa fa-spinner fa-pulse"></i>
        </template>
        <template v-else>
          {{ $t("login.create-account.submit") }}
        </template>
      </button>
    </form>

    <div class="regSuccess" v-if="successMsg">
      <p v-show="successMsg === 'new'">
        {{ $t("login.create-account.success-1") }}
        <span class="regemail">
          {{ formData.email }}
        </span>
        {{ $t("login.create-account.success-2") }}
      </p>
      <p v-show="successMsg === 'pending'">
        {{ $t("login.create-account.pending") }}
      </p>
      <p v-show="successMsg === 'exists'">
        {{ $t("login.create-account.exists-1") }} {{ formData.email }} {{ $t("login.create-account.exists-2") }}
      </p>
      <p v-show="successMsg === 'error'">
        {{ $t("login.create-account.error") }}
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import 'parsleyjs';

import * as endpoints from '@/common/endpoints';
import { loginMixins } from '@/common/mixins';

export default {
  name: 'Create Account',
  mixins: [loginMixins],
  data() {
    return {
      formData: {
        firstname: '',
        lastname: '',
        title: '',
        email: '',
        region: '',
        lang: '',
      },
      successMsg: null,
      isLoading: false,
    };
  },
  mounted() {
    this.parsleyTheForm('#create-account');
  },
  onBeforeUnmount() {
    $('#create-account').parsley().reset();
  },
  methods: {
    submitForm() {
      $('#create-account').parsley().on('form:submit', () => {
        this.isLoading = true;
        axios.post(
          `${endpoints.BASEAPIURL}mailfunctions${endpoints.EXT}`,
          {
            action: 'newuser',
            ...this.formData,
          },
        )
          .then((response) => {
            console.log(response);
            const json = response.data;
            this.isLoading = false;

            if (json.reply === 1) {
              if (json.status === 2) { // User already exists
                this.successMsg = 'exists';
              } else if (json.status === 0) { // User needs approval
                this.successMsg = 'pending';
              } else { // New "admin" user
                this.successMsg = 'new';
              }
            } else {
              this.successMsg = 'error';
            }
          })
          .catch((error) => {
            console.error(error);
          });

        return false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/login.scss';
</style>
