<template>
  <div v-if="info">
    <div class="container-xl">
      <div class="row">
        <div class="specs-bg" v-if="getDataByPrinter.background">
          <img :src="`${cmsURL}assets/${getDataByPrinter.background}`" :alt="getData.headline">
        </div>
        <div class="offset-md-2 offset-lg-3 col-md-8 col-lg-6">
          <h2 class="title">
            {{ getData.headline }}
          </h2>
          <br>
          <div class="section__content specs-table">
            <div class="specs-row">
              <div class="row">
                <div class="col-sm-3">
                  <strong>
                    {{ getData.headline_content_1 }}
                  </strong>
                </div>
                <div class="col-sm-9">
                  <div class="specs-inner-row" v-for="(row, idx) in getData.content_1" :key="idx">
                    <div class="row">
                      <div class="col-sm-5" v-html="row.sub_headline"></div>
                      <div class="col-sm-7" v-html="row.content"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="specs-row">
              <div class="row">
                <div class="col-sm-3">
                  <strong>
                    {{ getData.headline_content_2 }}
                  </strong>
                </div>
                <div class="col-sm-9">
                  <div class="specs-inner-row" v-for="(row, idx) in getData.content_2" :key="idx">
                    <div class="row">
                      <div class="col-sm-5" v-html="row.sub_headline"></div>
                      <div class="col-sm-7" v-html="row.content"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="specs-row" v-for="(row, idx) in getData.content_3" :key="idx">
              <div class="row">
                <div class="col-sm-3" v-html="row.sub_headline"></div>
                <div class="col-sm-9" v-html="row.content"></div>
              </div>
            </div>
          </div>
          <div class="specs-footnote" v-html="getData.notes"></div>
        </div>
      </div>
    </div>

    <ShareButton
      v-if="getData.shareable_pdf"
      :shareLink="`${cmsURL}assets/${getData.shareable_pdf}`"
      :shareLabel="`${$t('main-nav.technology')} - ${$t('technology.submenu.tech-specs')} ${printerName}`"
      :hash="hashify(`${$t('main-nav.technology')} - ${$t('technology.submenu.tech-specs')} ${printerName}`)"
      @share-this="shareThis" />
  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';
import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'TechnicalSpecs',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton,
  },
  data() {
    return {
      info: null,
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/technology_technical_specs`, {
      params: {
        fields: '*,translations.*,printer.printers_id',
      },
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.showMainTabs();
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/technology/technicalSpecs.scss';
</style>
