const basil = new window.Basil({
  // Namespace. Namespace your Basil stored data
  // default: 'b45i1'
  namespace: 'corrugated',

  // storages. Specify all Basil supported storages and priority order
  // default: `['local', 'cookie', 'session', 'memory']`
  storages: ['cookie', 'session'],

  // storage. Specify the default storage to use
  // default: detect best available storage among the supported ones
  storage: 'cookie',

  // expireDays. Default number of days before cookies expiration
  // default: 365
  expireDays: 1,
});

export default basil;
