<template>
  <Modal idModal="digitalPrint-2">
    <!-- eslint-disable max-len  -->
    <h2 class="title title--white text-center">
      Produce corrugated packaging faster
    </h2>

    <div class="faster-modal">
      <!-- Tabs -->
      <div class="faster-modal__buttons">
        <div class="faster-modal__buttons-single-btn"
          :class="activeTab === 1 && 'faster-modal-btn-active'"
          @click="changeTab(1)">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="enable-background:new 0 0 42 38" viewBox="0 0 42 38">
            <path d="M20.2 25.2c-1.4 0-2.4-.9-2.8-2.2-.3-1.2.3-2.5 1.4-3.3l3.9-2.4c.6-.3 1.1-.7 1.7-1 .1-.1.3-.1.4-.2.5-.2 1.1.4.9.9-.2.5-.4 1-.7 1.5-.7 1.5-1.4 3.1-2.1 4.6-.5 1.4-1.4 2.1-2.7 2.1zm3-6.2c-.1 0-.1 0 0 0-.1 0-.2 0-.3.1-.9.6-1.9 1.2-2.8 1.8-.2.2-.5.3-.7.5-.4.4-.6 1-.4 1.5.2.5.7.9 1.3.8.6 0 1-.4 1.2-.9.5-1 .9-2 1.4-3.1.1-.2.2-.4.3-.7z"/>
            <path d="M20.9 1.4c1 .1 2.6-.4 2.9.9.2.8-.7 1.4-1.4 1.3-.7-.1-.3 1.1-.4 1.5 0 .3.2.5.5.6 1.4.2 2.8.7 4.1 1.3 1.3.6 2.4 1.5 3.5 2.4.1 0 .3 0 .3-.1.3-.3.3-.3 0-.6-.5-.5-.6-.9-.1-1.4 2.4-2.5 2.1-2.6 4.5 0 .4.4 1.1 1 .5 1.6-.6.6-1.2 1.3-1.8 1.9-.3.3-.6.3-1 0-.3-.2-.5-.7-.9-.4-.2.2-.2.3-.1.5.4.5.8 1 1.1 1.5 3.7 5.5 3.4 13.2-.7 18.4-3.5 4.3-14.4 10.8-22.9-1.1-6.3-8.9-.7-22.3 10.2-24 .9-.1.5-1.1.6-1.7 0-.3-.1-.4-.4-.4-.4 0-.7-.1-1-.3-.6-.4-.6-1.2 0-1.7.7-.4 1.6-.2 2.5-.2zm-1.1 33.2c.1-.8-.4-2.5.8-2.6 1.1.1.6 1.6.7 2.3 0 .3.1.3.3.3 1.4-.2 2.8-.6 4.1-1.2.4-.2.4-.3.2-.7-.2-.7-1.1-1.4-.6-2.2.3-.4.9-.4 1.2.1.3.4.5.9.7 1.3.2.4.3.4.6.2 1.2-.9 2.2-1.9 3-3.1.2-.3.2-.4-.1-.6-.6-.5-2.1-1.3-1-2.1.6-.5 1.3.4 1.8.8.2.2.3.2.4-.1.8-1.5 1.2-3.1 1.3-4.8 0-.2-.1-.3-.2-.3-.7 0-2.2.2-2-1 .2-.9 1.3-.5 2-.6.7 0 .2-.9.3-1.3-.1-1.2-.5-2.4-1-3.5-.1-.2-.2-.2-.4-.1-.5.3-1 .5-1.6.7-.6.2-1-.2-1-.8.2-.8 1.1-.9 1.7-1.3.3-.1.3-.2.2-.5-.8-1.3-1.8-2.4-3-3.4-.2-.1-.3-.1-.4 0l-.9 1.2c-.3.4-1 .4-1.2-.1-.4-.7.6-1.3.9-2 .2-.2 0-.4-.2-.5-1.4-.8-2.8-1.3-4.4-1.5-.3-.1-.4 0-.4.3 0 .6.1 1.6-.7 1.7-1.1.1-1-.9-1-1.7 0-.3-.1-.4-.4-.3-1.5.3-3 .8-4.4 1.6-.3.2-.3.2-.1.5.3.4.6.7.8 1.1.2.3.1.8-.2 1-.9.6-1.4-.8-1.9-1.3-.2-.2-.2-.2-.4-.1-1.2 1-2.2 2.1-3 3.4-.1.2-.1.4.1.5.5.3 1.1.5 1.5.8.6.5 0 1.6-.7 1.2-.5-.2-1-.5-1.5-.7-.3-.1-.4-.1-.5.2-.5 1.4-1 2.9-1 4.4 0 .3.1.3.3.4h1.3c.9 0 1 1.4.1 1.5-.5.1-1 0-1.5.1-.2 0-.3.1-.3.3.5 1.9.9 3.5 1.6 5 .1.3.2.3.4.1.6-.4 1.4-1.5 2.1-.6.2.3.1.8-.2 1-.4.3-.7.6-1.1.8-.3.2-.3.3-.1.6.8 1.1 1.8 2.1 2.9 2.9.5.4.5.4.8-.2.2-.4.4-.8.7-1.2.6-.8 1.7.1 1.2.9-.2.5-.5.9-.7 1.4-.1.3-.1.4.2.6.8.5 3 1.3 3.9 1.2zM33.6 8.3c-.1-.4-.6-.7-.8-1h-.2c-.2.2-.8.5-.6.9.4.3.8 1.2 1.2.7.2-.2.3-.3.4-.6z"/>
          </svg>

          <h4 class="title title--smedium">
            Analog Production Flow Example
          </h4>
        </div>
        <div class="faster-modal__buttons-single-btn"
          :class="activeTab === 2 && 'faster-modal-btn-active'"
          @click="changeTab(2)">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="enable-background:new 0 0 42 38" viewBox="0 0 42 38">
            <path d="M23.9 25.3c-1.4 0-2.4-.9-2.8-2.2-.3-1.2.3-2.5 1.4-3.3l3.9-2.4c.6-.3 1.1-.7 1.7-1 .1-.1.3-.1.4-.2.5-.2 1.1.4.9.9-.2.5-.4 1-.7 1.5-.7 1.5-1.4 3.1-2.1 4.6-.5 1.3-1.4 2-2.7 2.1zm3-6.2s-.1-.1 0 0c-.1 0-.2 0-.3.1-.9.6-1.9 1.2-2.8 1.8-.2.2-.5.3-.7.5-.4.4-.6 1-.4 1.5.2.5.7.9 1.3.8.6 0 1-.4 1.2-.9.5-1 .9-2 1.4-3.1.1-.3.2-.5.3-.7z"/>
            <path d="M23.5 34.6v-1.8c0-.4.4-.8.8-.7.4 0 .7.4.7.8v1.5c0 .3.1.3.3.3 1.4-.2 2.8-.6 4.1-1.2.4-.2.4-.3.2-.7-.2-.4-.4-.9-.6-1.3-.1-.3-.2-.6 0-.9.3-.4.9-.4 1.2.1.3.4.5.9.7 1.3.2.4.3.4.6.2 1.2-.9 2.2-1.9 3-3.1.2-.3.2-.4-.1-.6-.4-.3-.7-.5-1.1-.8-.4-.4-.4-.9 0-1.2.3-.2.6-.2.9.1.3.2.6.5.9.7.2.2.3.2.4-.1.8-1.5 1.2-3.1 1.3-4.8 0-.2-.1-.3-.2-.3h-1.1c-.2 0-.4 0-.5-.1-.4-.1-.5-.5-.5-.9.1-.3.3-.6.7-.6h1.3c.3 0 .4-.1.4-.4 0-.3-.1-.6-.1-.9-.1-1.2-.5-2.4-1-3.5-.1-.2-.2-.2-.4-.1s-.3.2-.5.3c-.3.2-.7.3-1 .5-.6.2-1-.2-1-.8 0-.3.2-.5.5-.6l1.2-.6c.3-.1.3-.2.2-.5-.8-1.3-1.8-2.4-3-3.4-.2-.1-.3-.1-.4 0l-.9 1.2c-.1.1-.3.3-.5.3-.3.1-.6-.1-.7-.4-.2-.3-.2-.6 0-.8.3-.4.6-.8.8-1.1.1-.1.1-.3-.1-.4l-.1-.1c-1.4-.8-2.8-1.3-4.4-1.5-.3-.1-.4 0-.4.3v1c0 .4-.3.7-.7.7-.5 0-.9-.2-.9-.6v-1c0-.3-.1-.4-.4-.3-.1-.1-1.7.2-1.7-.8S23 5.8 23 5.8c.4-.1.6-.2.6-.7V4c0-.3-.1-.4-.4-.4-.4 0-.7-.1-1-.3-.6-.4-.6-1.2 0-1.7.3-.2.6-.3 1-.3h3.4c.5.1.9.3 1 .9.1.4 0 .7-.3 1-.3.2-.7.3-1.1.3-.4 0-.4.1-.4.4V5c0 .3.2.5.5.6 1.3.4 2.7.8 4 1.5 1.1.5 2.1 1.2 3.1 2l.4.4h.3c.3-.3.3-.3 0-.6l-.1-.1c-.5-.5-.5-.9 0-1.4.5-.6 1.1-1.1 1.6-1.6.4-.4.8-.4 1.3 0l1.6 1.6.5.5c.3.4.3.7 0 1.1-.6.6-1.2 1.3-1.8 1.9-.3.3-.6.3-1 0-.1-.1-.3-.2-.4-.4-.2-.2-.3-.2-.5 0s-.2.3-.1.5c.4.5.8 1 1.1 1.5 1.3 1.9 2.1 4.1 2.4 6.4.2 1 .2 2.1.2 3.1-.2 3.3-1.3 6.3-3.3 8.9-2.3 2.8-5.2 4.7-8.7 5.5-1.1.3-2.6.3-3.5.3-.9 0-1.8-.2-1.8-1 .1-.9 1.3-1 1.9-1.1zM37.3 8.3c0-.1-.1-.2-.1-.3-.2-.3-.5-.5-.7-.8h-.2l-.6.6c-.1.1-.1.2 0 .3l.7.7c.2.2.3.2.5 0 .2 0 .4-.2.4-.5zM19.1 22.5H3.6c-.5 0-.9-.4-.9-.9s.4-.9.9-.9H19c.5 0 .9.4.9.9s-.3.9-.8.9zM19.1 16.8H9.2c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h9.9c.5 0 .9.4.9.9-.1.5-.4.9-.9.9zM19.1 27.8H9.2c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h9.9c.5 0 .9.4.9.9-.1.6-.4.9-.9.9zM19.1 32.4h-3.9c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h3.9c.5 0 .9.4.9.9-.1.5-.4.9-.9.9zM19.1 12.4h-3.9c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h3.9c.5 0 .9.4.9.9-.1.5-.4.9-.9.9z"/>
          </svg>

          <h4 class="title title--smedium">
            Digital Production Flow Example
          </h4>
        </div>
      </div>
      <!-- END Tabs -->

      <!-- Content -->
      <div class="faster-modal__flow">
        <div class="flow__row"
          :class="activeTab === 2 && 'hidden'">
          <div class="flow-single-item flow-single-item--first">
            <h4 class="title title--small">
              Optimize Artwork
            </h4>
          </div>
          <div class="flow-single-item">
            <h4 class="title title--small">
              Prepress
            </h4>
          </div>
          <div class="flow-single-item">
            <h4 class="title title--small">
              Proof
            </h4>
          </div>
          <div class="flow-single-item">
            <h4 class="title title--small">
              Nesting/Layout
            </h4>
          </div>

          <div class="flow-single-item">
            <h4 class="title title--small">
              Plate Making
            </h4>
          </div>
          <div class="flow-single-item">
            <h4 class="title title--small">
              Ink Mixing
            </h4>
          </div>
          <div class="flow-single-item">
            <h4 class="title title--small title--arrow">
              Plate Mounting
            </h4>
          </div>
        </div>

        <div class="wrap-blue wrap-blue--top"
          :class="activeTab === 2 && 'active'">
          <div class="flow-single-item flow-single-item--blue flow-single-item--first">
            <h4 class="title title--small">
              Optimize Artwork
            </h4>
          </div>
          <div class="flow-single-item flow-single-item--blue">
            <h4 class="title title--small">
              Prepress
            </h4>
          </div>
          <div class="flow-single-item flow-single-item--blue">
            <h4 class="title title--small">
              Proof
            </h4>
          </div>
          <div class="flow-single-item  flow-single-item--blue">
            <h4 class="title title--small">
              Nesting/Layout
            </h4>
          </div>
        </div>

        <div class="flow__row flow__row--middle"
          :class="activeTab === 2 && 'hidden'">
          <div class="flow-single-item flow-single-item--middle flow-single-item--first">
            <h4 class="title title--small title--arrow">
              Curing/In<br/>
              Process
            </h4>
          </div>
          <div class="flow-single-item flow-single-item--middle">
            <h4 class="title title--small">
              Printing
            </h4>
          </div>
          <div class="flow-single-item flow-single-item--middle">
            <h4 class="title title--small">
              In Process<br/>
              Storage
            </h4>
          </div>
          <div class="flow-single-item flow-single-item--middle">
            <h4 class="title title--small">
              Aerator / Jogger
            </h4>
          </div>
          <div class="flow-single-item flow-single-item--middle">
            <h4 class="title title--small">
              Sheet Top Liner
            </h4>
          </div>
          <div class="flow-single-item flow-single-item--middle">
            <h4 class="title title--small">
              Make Ready<br/>
              Color
            </h4>
          </div>
          <div class="flow-single-item flow-single-item--middle flow-single-item--start">
            <h4 class="title title--small">
              Make Ready<br/>
              Registration
            </h4>
          </div>
        </div>

        <div class="wrap-blue wrap-blue--middle"
          :class="activeTab === 2 && 'active'">
          <div
            class="flow-single-item flow-single-item--blue">
            <h4 class="title title--small">
              Printing
            </h4>
          </div>
        </div>

        <div class="flow__row"
          :class="activeTab === 2 && 'hidden'">
          <div class="flow-single-item flow-single-item--start">
            <h4 class="title title--small">
              Ream Cut
            </h4>
          </div>
          <div class="flow-single-item">
            <h4 class="title title--small">
              Curing/In<br>Process
            </h4>
          </div>
          <div class="flow-single-item">
            <h4 class="title title--small">
              Shipping
            </h4>
          </div>
          <div class="flow-single-item">
            <h4 class="title title--small">
              Corrugator
            </h4>
          </div>
          <div class="flow-single-item">
            <h4 class="title title--small">
              In Process<br/>
              Storage
            </h4>
          </div>
          <div class="flow-single-item">
            <h4 class="title title--small">
              Die Cutting
            </h4>
          </div>
          <div class="flow-single-item">
            <h4 class="title title--small">
              Folding/<br/>
              Gluing
            </h4>
          </div>
          <div class="flow-single-item flow-single-item--end">
            <h4 class="title title--small">
              Shipping
            </h4>
          </div>
        </div>

        <div class="wrap-blue wrap-blue--bottom"
          :class="activeTab === 2 && 'active'">
          <div class="flow-single-item flow-single-item--blue">
            <h4 class="title title--small">
              Die Cutting
            </h4>
          </div>
          <div class="flow-single-item flow-single-item--blue">
            <h4 class="title title--small">
              Folding/<br/>
              Gluing
            </h4>
          </div>
          <div class="flow-single-item flow-single-item--blue flow-single-item--end">
            <h4 class="title title--small">
              Shipping
            </h4>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/modal/Modal.vue';

export default {
  name: 'WhyDigitalPrintModal2',
  components: {
    Modal,
  },
  data() {
    return {
      activeTab: 1,
    };
  },
  methods: {
    changeTab(target) {
      this.activeTab = target;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/why-digital/digital-value/why-digital-print/modal2';
</style>
