<template>
  <form
    class="additionalForm"
    ref="form">
    <div class="d-flex w-100 flex-row align-items-stretch justify-content-center mb-1">
      <div class="d-flex number-col align-items-center justify-content-center">
        <span class="h2 m-0">
          {{ idx }}
        </span>
      </div>

      <div class="form-col px-3 pt-3 pb-2">
        <div class="form-group text-left">
          <label for="email">
            {{ $t('login.new-presentation.email') }}*
          </label>
          <input
            type="email"
            class="form-control"
            :placeholder="$t('login.new-presentation.email')"
            required
            v-model="localData.email">
        </div>

        <div class="row">
          <div class="form-group col-md-6 pr-md-2 text-left">
            <label for="firstName">
              {{ $t('login.new-presentation.first-name') }}*
            </label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('login.new-presentation.first-name')"
              required
              v-model="localData.firstname">
          </div>
          <div class="form-group col-md-6 pl-md-2 text-left">
            <label for="lastName">
              {{ $t('login.new-presentation.last-name') }}*
            </label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('login.new-presentation.last-name')"
              required
              v-model="localData.lastname">
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6 pr-md-2 text-left">
            <label for="title">
              {{ $t('login.new-presentation.title') }}
            </label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('login.new-presentation.title')"
              v-model="localData.title">
          </div>
          <div class="form-group col-md-6 pl-md-2 text-left">
            <label for="phoneNumber">
              {{ $t('login.new-presentation.phone') }}
            </label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('login.new-presentation.phone')"
              v-model="localData.phone">
          </div>
        </div>

        <div class="form-group my-3 d-flex justify-content-end">
          <a
            href="javascript:void(0)"
            class="cta cta--border small ra-gtm-event"
            data-gtm-section="Presentation Login"
            data-gtm-label="Delete Contact"
            @click="(formAction === 'insert')
              ? $emit('hide-form')
              : deleteContact(localData.id)">
            <template v-if="formAction === 'insert'">
              {{ $t('common.actions.close') }}
              <i class="fa fa-times"></i>
            </template>
            <template v-else>
              {{ $t('common.actions.delete') }}
              <i class="fa fa-trash-o"></i>
            </template>
          </a>

          <button
            href="javascript:void(0)"
            class="cta cta--border small ra-gtm-event"
            data-gtm-section="Presentation Login"
            type="submit"
            :data-gtm-label="`${capitalize(formAction)} Contact`"
            @click="submitForm(formAction)">
            <template v-if="isLoading">
            {{ $t('common.actions.updating') }}
              <i class="fa fa-spinner fa-pulse"></i>
            </template>
            <template v-else>
              <template v-if="formAction === 'insert'">
                {{ $t('common.actions.save') }}
                <i class="fa fa-save"></i>
              </template>
              <template v-else>
                {{ $t('common.actions.update') }}
                <i class="fa fa-rotate-left"></i>
              </template>
            </template>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import { mapState } from 'vuex';

import * as endpoints from '@/common/endpoints';
import { filters, loginMixins } from '@/common/mixins';

export default {
  name: 'Contact Form',
  mixins: [filters, loginMixins],
  emits: ['hide-form', 'submit', 'delete-contact'],
  props: {
    formAction: String,
    data: Object,
    idx: Number,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.parsleyTheForm(this.$refs.form);
  },
  methods: {
    /**
     * @param {string} action insert, update
     */
    submitForm(action) {
      $(this.$refs.form).parsley().on('form:submit', () => {
        this.isLoading = true;

        axios.post(
          `${endpoints.BASEAPIURL}presentationfunctions${endpoints.EXT}`,
          {
            action,
            ...this.localData,
            // Added conditionally if action is insert
            ...(action === 'insert' && {
              company: this.$parent.currentCompany,
              primarycontact: 0,
            }),
            login: this.loggedin,
          },
        )
          .then((response) => {
            const json = response.data;
            if (json.reply === 1) {
              this.isLoading = false;

              // Clear Parent Data (NewPresentation.vue)
              this.$parent.isCreatingContact = false;
              this.$parent.temporaryData = {
                email: '',
                firstname: '',
                lastname: '',
                title: '',
                phone: '',
              };

              this.$emit('submit');
            } else {
              console.error(json.message);
            }
          })
          .catch((error) => {
            console.error(error);
          });
        return false;
      });
    },
    /**
     * @param {number} id The id of the user to be deleted
     */
    deleteContact(id) {
      axios.post(
        `${endpoints.BASEAPIURL}presentationfunctions${endpoints.EXT}`,
        {
          action: 'delete',
          id,
          login: this.loggedin,
        },
      )
        .then(() => {
          console.log(`Deleted contact id: ${id}`);

          this.$emit('delete-contact', id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    localData() {
      return this.data;
    },
    ...mapState([
      'loggedin',
    ]),
  },
};
</script>

<style lang="scss">
  @import '@/scss/views/new-presentation.scss';
</style>
