<template>
  <!-- eslint-disable max-len -->
  <div v-if="info">
    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ subSections[currentSubsection].label }}
        </h2>
      </div>

      <SubsectionNavLeft
        :currentComponent="subSections[currentSubsection]"
        gtmSubsection="Workflow"
        parent="E2E Solutions"
        :subSections="subSections"
        @click-subnav="changeTab" >
        <component
          :data="getData"
          :is="subSections[currentSubsection].component" />
      </SubsectionNavLeft>
    </div>

    <ShareButton
      v-if="getData.shareable_pdf"
      :hash="hashify(`${$t('main-nav.service-support')} - ${$t('end-to-end.submenu.workflow')} - ${printerName}`)"
      :shareLabel="`${$t('main-nav.service-support')} - ${$t('end-to-end.submenu.workflow')} - ${printerName}`"
      :shareLink="`${getData.video}\n${baseURL}${getData.shareable_pdf}`"
      @share-this="shareThis" />

  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import ShareButton from '@/components/ui/ShareButton.vue';
import SubsectionNavLeft from '@/layout/SubsectionNavLeft.vue';

import Video from './workflow/Video.vue';
import DigitalPreprint from './workflow/DigitalPreprint.vue';
import OnePackageSoftware from './workflow/OnePackageSoftware.vue';

export default {
  name: 'Workflow',
  mixins: [filters, defaultMixins],
  components: {
    DigitalPreprint,
    OnePackageSoftware,
    ShareButton,
    SubsectionNavLeft,
    Video,
  },
  data() {
    return {
      baseURL: endpoints.BASEURL,
      currentSubsection: 0,
      info: null,
    };
  },
  created() {
    axios.get('data/workflow.json')
      .then(({ data: { data } }) => {
        console.log(data);
        this.info = data;
      });
  },
  mounted() {
    this.showMainTabs();
  },
  methods: {
    changeTab(payload) {
      this.currentSubsection = payload.id;
    },
  },
  watch: {
    currentPrinter() {
      this.currentSubsection = 0;
    },
  },
  computed: {
    /**
     * The items in the array MUST match the components name files
     * these values are trimmed and passed as components names.
     */
    subSections() {
      const SubSectionsMapping = {
        1: [
          {
            id: 0,
            component: 'OnePackageSoftware',
            label: this.getData.one_package_software_headline,
            shareLabel: this.getData.one_package_software_headline,
          },
        ],
        2: [
          {
            id: 0,
            component: 'Video',
            label: this.getData.title_workflow_video,
            shareLabel: this.getData.title_workflow_video,
          },
          {
            id: 1,
            component: 'OnePackageSoftware',
            label: this.getData.one_package_software_headline,
            shareLabel: this.getData.one_package_software_headline,
          },
          {
            id: 2,
            component: 'DigitalPreprint',
            label: this.getData.digital_preprint_headline,
            shareLabel: this.getData.digital_preprint_headline,
          },
        ],
        3: [
          {
            id: 0,
            component: 'Video',
            label: this.getData.title_workflow_video,
            shareLabel: this.getData.title_workflow_video,
          },
          {
            id: 1,
            component: 'OnePackageSoftware',
            label: this.getData.one_package_software_headline,
            shareLabel: this.getData.one_package_software_headline,
          },
        ],
      };

      return SubSectionsMapping[this.currentPrinter];
    },
  },
};
</script>

<style lang="scss" scoped>
  .section__title {
    margin-bottom: 30px;
  }
</style>
