import axios from 'axios';
import $ from 'jquery';
import { mapState } from 'vuex';

import * as endpoints from '@/common/endpoints';

export const cardsMixins = {
  data() {
    return {
      itemOpen: null,
      maxHeight: 0,
    };
  },
  methods: {
    setOpen(id) {
      this.itemOpen = id;
    },
    /**
     * This function filters the items based on
     * any given category name
     * @param {Object} item - Item to be filtered
     * @param {String} category - Filter name inside Directus
     * @param {Boolean} multipleFilters - If there are more than one
     * filter in the current page, instance.
     * @param {String} itemName - Just in case the Single filter's name
     * is different from the category name
     * @param {String} prefix - Specify the string before the category
     * Example: the prefix for filters_software is filters_ (default)
     * @returns
     */
    doFilter(item, category, multipleFilters = true, itemName = null, prefix = 'filters_') {
      const arrayOfSelectedFilters = (multipleFilters)
        ? this.selectedFilters[category]
        : this.selectedFilters;

      const currentItemTags = item[`${prefix}${category}`].map((tag) => tag[`${itemName || category}_id`].id || tag[`${itemName || category}_id`]);

      if (arrayOfSelectedFilters.length > 0) {
        return currentItemTags.some((tag) => arrayOfSelectedFilters.includes(tag));
      }

      return true;
    },
    /**
     * This Function retrieves all the filters
     * in one card and make the translations
     * @param {Object} card - parent item
     * @param {String} category - filter name inside Directus Database
     * @param {String} itemName - Just in case the Single filter's name
     * is different from the category name
     * @param {String} prefix - Specify the string before the category
     * Example: the prefix for filters_software is filters_ (default)
     * @returns Array with the translation of the filter's name
     */
    getTranslatedFilter(card, category, itemName = null, prefix = 'filters_') {
      return card[`${prefix}${category}`]
        .map((filterItem) => filterItem[`${itemName || category}_id`].translations
          .filter(
            (i) => i.languages_code === this.$i18n.locale,
          )[0]);
    },
    matchHeight(dataName) {
      const items = document.querySelectorAll(dataName);
      const allHeights = [];

      for (let i = 0; i < items.length; i += 1) {
        items[i].style.height = 'auto';
        allHeights.push(items[i].offsetHeight);
      }
      if (Number.isNaN(this.maxHeight)) {
        return false;
      }
      this.maxHeight = Math.max.apply(null, allHeights);

      for (let i = 0; i < items.length; i += 1) {
        items[i].style.height = `${this.maxHeight}px`;
      }

      return true;
    },

  },
  computed: {
    // Receives the Key of the filter
    filterList() {
      return (category) => {
        const filterItems = this.items.map((item) => item[category]);
        return [...new Set(filterItems.flat())];
      };
    },
  },
};

export const defaultMixins = {
  data() {
    return {
      cmsURL: endpoints.CMS,
      isReady: false,
    };
  },
  methods: {
    hideMainTabs() {
      $('.js-header-tabs').fadeOut('fast');
    },
    showMainTabs() {
      $('.js-header-tabs').fadeIn('fast');
    },
    insertTrackingtoHPDB(section, subsection, label) {
      // consolidated contacts
      const consC = this.companyContact.team
        .map((i) => i.email)
        .join(', ');

      axios.post(
        `${endpoints.BASEAPIURL}inserttrackingV2${endpoints.EXT}`,
        {
          email: this.companyContact.email,
          salesPerson: this.salesPerson.email,
          addContacts: consC,
          section,
          subsection,
          detail: label,
          lang: this.$i18n.locale,
          printer: this.currentPrinter,
        },
      )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    shareTrackingtoHPDB(label, link, salesperson, sharedwith) {
      axios.post(
        `${endpoints.BASEAPIURL}insertsharetrackingV2${endpoints.EXT}`,
        {
          email: salesperson,
          sharedwith,
          label,
          link,
          lang: this.$i18n.locale,
          printer: this.currentPrinter,
        },
      )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    clickTracked(e) {
      const $gtmsection = e.target.getAttribute('data-gtm-section');
      const $gtmsubsection = e.target.getAttribute('data-gtm-subsection');
      const $gtmlabel = e.target.getAttribute('data-gtm-label');

      // set true to test tracking in localhost etc
      const bypass = false;
      if ((window.location.href.indexOf('dev') >= 0
        || window.location.href.indexOf('localhost') >= 0
        || window.location.href.indexOf('vue') >= 0)
        && !bypass) {
        console.log('1 not tracking');
      } else {
        this.insertTrackingtoHPDB($gtmsection, $gtmsubsection, $gtmlabel);
      }
    },
    // Share List Tracking
    shareTracking() {
      // set true to test tracking in localhost etc
      const bypass = true;
      if ((window.location.href.indexOf('dev') >= 0
        || window.location.href.indexOf('localhost') >= 0
        || window.location.href.indexOf('vue') >= 0)
        && !bypass) {
        console.log('2 not share tracking');
      } else {
        console.log(this.sharedList.length);
        console.log(this.sharedList);
        for (let index = 0; index < this.sharedList.length; index += 1) {
          this.shareTrackingtoHPDB(
            this.sharedList[index].shareLabel,
            this.sharedList[index].shareLink,
            this.salesPerson.email,
            this.companyContact.email,
            this.currentPrinter,
          );
        }
      }
    },
    shareThis(payload) {
      if (payload.checked) {
        return this.$store.commit('addToShare', payload.data);
      }
      return this.$store.commit('removeItem', payload.data);
    },
    updateShared(sharedArr) {
      $('.js-share-button').prop('checked', false);

      sharedArr.map((item) => $(`.js-share-button[data-hash="${item.hash}"]`).prop('checked', true));
    },
  },
  watch: {
    sharedList(latestState) {
      if (this.updateShared) {
        this.updateShared(latestState);
      }
    },
  },
  mounted() {
    this.updateShared(this.sharedList);
  },
  updated() {
    if (this.updateShared) {
      this.updateShared(this.sharedList);
    }
  },
  computed: {
    // Maps State to data
    ...mapState([
      'companyContact',
      'currentPrinter',
      'salesPerson',
      'sharedList',
    ]),
    // Print printer name based on ID
    printerName() {
      const printersObject = {
        1: 'T470S',
        2: 'T1195i',
        3: 'T700i',
      };
      return printersObject[this.currentPrinter];
    },
    // Get current Printer's data
    getDataByPrinter() {
      if (this.info) {
        const [currentPrinterData] = this.info
          .filter((item) => item.printer
            .some((printer) => printer.printers_id === this.currentPrinter));

        return currentPrinterData;
      }

      return false;
    },
    // Get Translated data
    getData() {
      if (this.info) {
        // Set fallback to english data
        const [defaultEnglishData] = this.getDataByPrinter.translations
          .filter((item) => item.languages_code === this.$i18n.fallbackLocale);

        // Get current language data
        const [translatedData] = this.getDataByPrinter.translations
          .filter((item) => item.languages_code === this.$i18n.locale);

        return translatedData || defaultEnglishData;
      }

      return false;
    },
  },
};

export const filters = {
  methods: {
    slugify(string) {
      const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
      const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
      const p = new RegExp(a.split('').join('|'), 'g');

      return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word characters
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
    },
    trim(string) {
      return string.replace(/ /g, '');
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    uppercase(string) {
      return string.toUpperCase();
    },
    hashify(string) {
      return window.btoa(unescape(encodeURIComponent(string)));
    },
  },
};

export const loginMixins = {
  methods: {
    parsleyTheForm(target) {
      const $validationOptions = {
        trigger: 'focusout',
        excluded: ':hidden',
        errorsWrapper: '<span></span>',
        errorTemplate: '<span class="msg-is-invalid"></span>',
      };

      return $(target).parsley($validationOptions)
        .on('field:error', (data) => {
          console.error(`-- Validation Error -- for ${target}`, data.$element[0].name, data.$element[0].class);
        });
    },
  },
};
