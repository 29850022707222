<template>
  <div class="intro">
    <template v-if="info">
      <div class="intro__content container-xl">
        <div class="row">
          <div class="col-7">
            <h4 class="animate">
              {{ getData.welcome }}, {{ companyContact.firstname }}
            </h4>
            <h1 class="title title--big animate" v-html="getData.headline">
            </h1>
            <p class="mb-4 animate" v-html="getData.text"></p>

            <div class="animate" v-if="getDataByPrinter.video">
              <Button
                href="javascript:void(0)"
                data-gtm-section="Intro"
                data-gtm-subsection=""
                data-gtm-label="Watch Intro Video"
                data-target="#intro-video"
                data-toggle="modal"
                @click="clickTracked($event)">
                {{ $t("buttons.watch-video") }}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div class="intro__bg">
        <img :src="`${cmsURL}assets/${getDataByPrinter.background}`" />
      </div>

      <div
        v-if="getData.show_message_box"
        class="box-msg"
        :class="{'active': isBoxOpen}">

        <svg
          viewBox="0 0 657 517" fill="none"
          class="box-msg__deco">
          <rect v-show="isBoxOpen" x="276" y="21" class="st0" width="27" height="27"/>
          <rect v-show="isBoxOpen" x="57" y="35" class="st0" width="27" height="27"/>
          <rect v-show="isBoxOpen" x="48" y="118" class="st0" width="27" height="27"/>
          <rect v-show="isBoxOpen" x="81" y="87" class="st0" width="27" height="27"/>
          <rect x="573" y="40" class="st0" width="28" height="28"/>
          <rect x="511" y="48" class="st0" width="42" height="42"/>
          <rect x="630" y="63" class="st0" width="27" height="27"/>
          <rect x="545" class="st0" width="27" height="27"/>
          <rect x="67" y="475" class="st0" width="42" height="42"/>
          <rect x="41" y="425" class="st0" width="27" height="27"/>
          <rect y="470" class="st0" width="37" height="37"/>
        </svg>

        <div class="box-msg__intro"
        @click="toggleBox">
          <p v-if="getData.box_intro_headline">
            {{ getData.box_intro_headline }}
          </p>
          <p v-if="getData.box_intro_text" class="big">
            {{ getData.box_intro_text }}
          </p>
        </div>

        <div class="box-msg__content">
          <h4 v-if="getData.box_content_headline" class="title">
            {{ getData.box_content_headline }}
          </h4>

          <div v-html="getData.box_content_content"></div>

          <button
            class="hamburger--collapse is-active close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            @click="toggleBox">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>

        </div>
      </div>

      <Modal
        v-if="getDataByPrinter.video"
        idModal="intro-video">
        <Media
          data-gtm-section="Intro"
          data-gtm-label="Play Intro Video"
          :src="getDataByPrinter.video" />
      </Modal>
    </template>
  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { defaultMixins } from '@/common/mixins';

import Button from '@/components/ui/Button.vue';
import Modal from '@/components/modal/Modal.vue';
import Media from '@/components/ui/Media.vue';

export default {
  name: 'Intro',
  mixins: [defaultMixins],
  components: {
    Button,
    Modal,
    Media,
  },
  data() {
    return {
      cmsURL: endpoints.CMS,
      info: null,
      isBoxOpen: false,
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/intro`, {
      params: {
        fields: '*,translations.*,printer.printers_id',
      },
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.showMainTabs();
  },
  methods: {
    toggleBox() {
      this.isBoxOpen = !this.isBoxOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/intro.scss';
</style>
