<template>
  <Media
    data-gtm-section="E2E Solutions"
    data-gtm-subsection="Workflow"
    data-gtm-label="Watch Workflow Video"
    :src="data.video"
    :poster="data.video_poster"
    :title="data.caption_video" />
</template>

<script>
import Media from '@/components/ui/Media.vue';

export default {
  name: 'WorkflowVideo',
  props: {
    data: Object,
  },
  components: {
    Media,
  },
};
</script>
