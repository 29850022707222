<template>
  <div class="section--top customer-stories">
    <transition name="fade">
      <Loading v-if="!isReady" />
    </transition>

    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ $t('main-nav.customer-stories')}}
        </h2>
      </div>

      <div class="section__content">
        <div class="row">
          <div class="col-md-3">
            <div class="wrap-sidebar-filter">
              <span>
                {{ $t('filters.filtering-options') }}
              </span>

              <div class="sidebar-filter">
                <h5 class="title title--medium">
                  {{ $t('filters.industry') }}
                </h5>
                <div class="sidebar-filter__list">
                  <template v-for="(filter, index) in translatedFilters('industry')" :key="index">
                    <input
                      type="checkbox"
                      :value="filter.industry_id"
                      :id="slugify(filter.name)"
                      v-model="selectedFilters.industry">
                    <label :for="slugify(filter.name)">
                      <span class="input">
                        <svg width="12px" height="10px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span class="label">
                        {{ filter.name }}
                      </span>
                    </label>
                  </template>
                </div>
              </div>

              <div class="sidebar-filter">
                <h5 class="title title--medium">
                  {{ $t('filters.region') }}
                </h5>
                <div class="sidebar-filter__list">
                  <template v-for="(filter, index) in translatedFilters('region')" :key="index">
                    <input
                      type="checkbox"
                      :value="filter.region_id"
                      :id="slugify(filter.name)"
                      v-model="selectedFilters.region">
                    <label :for="slugify(filter.name)">
                      <span class="input">
                        <svg width="12px" height="10px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span class="label">
                        {{ filter.name }}
                      </span>
                    </label>
                  </template>
                </div>
              </div>

              <!-- <div class="sidebar-filter">
                <h5 class="title title--medium">
                  {{ $t('filters.equipment') }}
                </h5>
                <div class="sidebar-filter__list">
                  <template v-for="(filter, index) in translatedFilters('equipment')" :key="index">
                    <input
                      type="checkbox"
                      :value="filter.equipment_id"
                      :id="slugify(filter.name)"
                      v-model="selectedFilters.equipment">
                    <label :for="slugify(filter.name)">
                      <span class="input">
                        <svg width="12px" height="10px">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span class="label">
                        {{ filter.name }}
                      </span>
                    </label>
                  </template>
                </div>
              </div> -->
            </div>
          </div>

          <div class="col-md-9">
            <div class="row">
              <div
                class="col-sm-6 col-lg-4 col-xl-3"
                v-for="(item, index) in filteredCards"
                :key="index" >
                <Card
                  class="data-story-card"
                  :data="item"
                  data-section="Customer Stories"
                  data-subsection=""
                  :id-card="index"
                  ref="card"
                  :share-section="`${$t('main-nav.customer-stories')}`"
                  target-modal="#modal-story"
                  @share-card="shareThis"
                  @show-modal="setOpen" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalStory
      :data="filteredCards[itemOpen] || {}"
      :index="itemOpen || 0"
      :data-section="'Customer Stories'"
      idModal="modal-story"
      modal-type="story"
      :share-section="`${$t('main-nav.customer-stories')}`"
      @share-card="shareThis"
      @close-modal="clearLinks"
      @change-modal="changeModal"></ModalStory>
  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins, cardsMixins } from '@/common/mixins';

import Loading from '@/components/ui/Loading.vue';
import Card from '@/components/card/Card.vue';
import ModalStory from '@/components/modal/ModalStory.vue';

export default {
  name: 'CustomerStories',
  mixins: [filters, defaultMixins, cardsMixins],
  components: {
    Loading,
    Card,
    ModalStory,
  },
  data() {
    return {
      filters: {
        industry: [],
        region: [],
        equipment: [],
      },
      items: [],
      selectedFilters: {
        industry: [],
        region: [],
        equipment: [],
      },
    };
  },
  created() {
    // Get all industries
    axios.get(`${endpoints.CMS}items/industry`, {
      params: {
        fields: '*,translations.*',
      },
    })
      .then(({ data: { data } }) => { this.filters.industry = data; });

    // Get all Regions
    axios.get(`${endpoints.CMS}items/region`, {
      params: {
        fields: '*,translations.*',
      },
    })
      .then(({ data: { data } }) => { this.filters.region = data; });

    // Get all Equipments
    axios.get(`${endpoints.CMS}items/equipment`, {
      params: {
        fields: '*,translations.*',
      },
    })
      .then(({ data: { data } }) => { this.filters.equipment = data; });

    // Get all Cards
    axios.get(`${endpoints.CMS}items/customer_stories`, {
      params: {
        fields: `*,
          translations.*,
          filters_industry.industry_id.*,
          filters_industry.industry_id.translations.*,
          filters_region.region_id.*,
          filters_region.region_id.translations.*,
          filters_equipment.equipment_id.*,
          filters_equipment.equipment_id.translations.*`,
        sort: 'identifier',
      },
    })
      .then(({ data: { data } }) => { this.items = data; })
      .then(() => { this.isReady = true; });
  },
  mounted() {
    this.hideMainTabs();
  },
  updated() {
    this.matchHeight('.data-story-card .category');
    this.matchHeight('.data-story-card .title');
  },
  methods: {
    clearLinks() {
      this.itemOpen = null;
    },
    changeModal(direction) {
      const totalItems = this.filteredCards.length - 1;

      this.updateShared(this.$store.state.sharedList);

      if (direction === 'prev') {
        if (this.itemOpen === 0) {
          this.itemOpen = totalItems;
        } else {
          this.itemOpen -= 1;
        }
      } else if (this.itemOpen === totalItems) {
        this.itemOpen = 0;
      } else {
        this.itemOpen += 1;
      }
    },
  },
  computed: {
    // Print filters filtered with current locale
    translatedFilters() {
      return (cat) => this.filters[cat]
        .map((filter) => {
          // Set English for fallback
          const translatedFilter = filter.translations
            .filter((i) => i.languages_code === this.$i18n.locale)[0];
          // Filter translated items
          const fallbackFilter = filter.translations
            .filter((i) => i.languages_code === this.$i18n.fallbackLocale)[0];

          return translatedFilter || fallbackFilter;
        });
    },
    // Filter the translated data inside the cards
    translatedCards() {
      return this.items
        .map((card) => {
          // Set English for fallback
          const translatedCard = card.translations
            .filter((i) => i.languages_code === this.$i18n.locale)[0];
          // Filter translated items
          const fallbackCard = card.translations
            .filter((i) => i.languages_code === this.$i18n.fallbackLocale)[0];

          return {
            ...card,
            content: translatedCard || fallbackCard,
          };
        })
        .map((card) => (
          {
            ...card,
            filters: {
              ...card.filters,
              industry: this.getTranslatedFilter(card, 'industry'),
            },
          }
        ))
        .map((card) => (
          {
            ...card,
            filters: {
              ...card.filters,
              equipment: this.getTranslatedFilter(card, 'equipment'),
            },
          }
        ))
        .map((card) => (
          {
            ...card,
            filters: {
              ...card.filters,
              region: this.getTranslatedFilter(card, 'region'),
            },
          }
        ));
    },
    // Return cards after filtering in the front-end
    filteredCards() {
      const hasindustry = this.selectedFilters.industry.length;
      const hasRegion = this.selectedFilters.region.length;
      const hasEquipment = this.selectedFilters.equipment.length;
      const allData = this.translatedCards;

      if (hasindustry === 0 && hasRegion === 0 && hasEquipment === 0) {
        return allData;
      }

      return allData
        .filter((card) => this.doFilter(card, 'industry'))
        .filter((card) => this.doFilter(card, 'region'))
        .filter((card) => this.doFilter(card, 'equipment'));
    },
  },
};
</script>

<style lang="scss" scoped>
.section__title {
  display: block;
  margin-bottom: 60px;
}

.sidebar-filter {
  margin-top: 20px;
  padding-top: 7px;
  border-top: 1px solid white;
}

.sidebar-filter__list {
  label {
    position: relative;
    display: block;
    padding: 6px 8px;
    border-radius: 6px;
    cursor: pointer;
    transition: all .2s ease;

    user-select: none;

    span {
      display: inline-block;
      vertical-align: middle;
    }

    .input {
      position: relative;
      width: 25px;
      height: 25px;
      border: 1px solid #CCCFDB;
      border-radius: 6px;
      background-color: #63656B;
      box-shadow: 0 1px 1px rgba(#00104B,.05);
      transition: all .2s ease;

      svg {
        position: absolute;
        top: 6px;
        left: 5px;
        opacity: 0;
        transition: all .3s ease;

        fill: none;
        stroke: $color_blue;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
    }

    .label {
      padding-left: 8px;
      color: white;
      font-size: 16px;
      font-family: $font_light;
      line-height: 18px;
    }

    &:hover .input {
      border-color: $color_blue;
    }
  }

  input {
    position: absolute;
    visibility: hidden;
    height: 25px;

    &:checked + label {
      .input {
        border-color: $color_blue;
        background-color: white;

        svg {
          opacity: 1;
        }
      }

      .label {
        font-family: $font_default;
      }
    }
  }
}
</style>
