<template>
  <form @submit.prevent="login">
    <div class="form-group text-left">
      <label for="loginEmail">
        {{ $t('login.common.username') }}
      </label>
      <input
        :class="['form-control', { 'error': errors.username }]"
        id="loginEmail"
        :placeholder="$t('login.common.placeholder.email')"
        type="email"
        v-model.trim="username">
    </div>
    <div class="form-group text-left">
      <label for="loginPassword">
        {{ $t('login.common.password') }}
      </label>
      <input
        :class="['form-control', { 'error': errors.password }]"
        id="loginPassword"
        :placeholder="$t('login.common.placeholder.password')"
        type="password"
        v-model.trim="password">
    </div>
    <div class="incorrect" v-show="!isValid">
      <p>
        {{ $t('login.common.data-error') }}
      </p>
    </div>
    <button type="submit" class="cta mx-auto my-2">
      {{ $t('login.common.submit') }}
    </button>
  </form>
</template>

<script>
import axios from 'axios';

import { defaultMixins } from '@/common/mixins';

import * as endpoints from '@/common/endpoints';
import basil from '@/common/cookies';

export default {
  name: 'Login Form',
  mixins: [defaultMixins],
  data() {
    return {
      baseURL: endpoints.BASEURL,
      errors: {
        username: false,
        password: false,
      },
      isValid: true,
      username: null,
      password: null,
    };
  },
  methods: {
    login() {
      if (this.username && this.password) {
        axios.post(
          `${endpoints.BASEAPIURL}getuser${endpoints.EXT}`,
          {
            username: this.username,
            password: this.password,
          },
        )
          .then(({ data }) => {
            if (data.reply === 1) {
              this.$store.commit('fillSalesPersonData', {
                ...data,
                email: this.username,
              });
              this.$store.commit('login', 1);
              this.$emit('success');

              // Cookie to keep loggin in session
              basil.set('user', {
                ...data,
                email: this.username,
              });

              // Cookie to locale
              const localeToSet = (!data.language) ? 'en' : data.language;

              basil.set('locale', localeToSet);
              this.$i18n.locale = localeToSet;

              // Trigger tracking function
              this.insertTrackingtoHPDB('all', '', 'login');
            } else {
              this.isValid = false;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.isValid = false;
      }
      return false;
    },
    fakeLogin() {
      // Local fake data
      const fakeData = {
        reply: '1',
        firstname: 'Sample',
        lastname: 'Person',
        title: 'Tester',
        email: 'mateo@rab2b.com',
        superadmin: 0,
        photo: `${this.baseURL}images/headshot.jpg`,
        team: [{
          firstname: 'William', lastname: 'Ludwig', id: '286', photo: `${this.baseURL}images/headshot.jpg`, title: 'Account Executive',
        }, {
          firstname: 'AJ', lastname: 'Wang', id: '285', photo: `${this.baseURL}images/headshot.jpg`, title: 'Solutions Manager Worldwide',
        }, {
          firstname: 'Aaron', lastname: 'Lautenschlager', id: '288', photo: `${this.baseURL}images/headshot.jpg`, title: 'Solutions Manager',
        }, {
          firstname: 'Michael', lastname: 'Zitser', id: '292', photo: `${this.baseURL}images/headshot.jpg`, title: 'Solutions Manager Americas',
        }],
        region: 'EMEA',
      };
      // Local Test data
      this.$store.commit('login', 1);
      this.$store.commit('fillSalesPersonData', fakeData);
      this.$emit('success');
    },
    errorHandler(data) {
      this.errors[data] = false;

      if (this[data] === '') {
        this.errors[data] = true;
      }
    },
  },
  watch: {
    username() {
      this.errorHandler('username');
    },
    password() {
      this.errorHandler('password');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/login.scss';
</style>
