<template>
  <div>
    <!-- eslint-disable max-len -->
    <form id="primaryForm">
      <div class="form-group text-left position-relative">
        <label for="company">
          {{ $t('login.new-presentation.company') }}
        </label>
        <input
          autocomplete="no"
          class="form-control js-input-drop-down"
          id="company"
          name="company"
          :placeholder="$t('login.new-presentation.company-name')"
          required
          type="text"
          v-model="currentCompany"
          @focus="showDropdown"
          @keyup="showDropdown"
          @blur="hideDropdown">

        <div class="input-drop-down" style="display:none;">
          <ul class="mt-2 p-2">
            <li>
              <a class="blue" href="javascript:void(0)" @click="createNewCompany">
                Insert New Contact
              </a>
            </li>

            <li v-for="(name, index) in companyList" :key="index">
              <a href="javascript:void(0)" @click="openCompanyDetail(name)">
                {{ name }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <h2 class="text-left mt-5 mb-4 js-company-name">
        {{ currentCompany }}
      </h2>

      <!-- Primary Contact Form -->
      <div class="primary-contact mt-4">
        <h5 class="divider l-spacing-2 pb-1">
          {{ $t('login.new-presentation.primary-account') }}
        </h5>

        <div class="form-group text-left">
          <label for="email">
            {{ $t('login.new-presentation.email') }}*
          </label>
          <input
            class="form-control"
            name="emailAddress"
            :placeholder="$t('login.new-presentation.email')"
            required
            type="email"
            v-model="primaryContact.email">
        </div>

        <div class="row">
          <div class="form-group col-md-6 pr-md-2 text-left">
            <label for="firstName">
              {{ $t('login.new-presentation.first-name') }}*
            </label>
            <input
              class="form-control"
              name="firstName"
              :placeholder="$t('login.new-presentation.first-name')"
              required
              type="Text"
              v-model="primaryContact.firstname">
          </div>

          <div class="form-group col-md-6 pl-md-2 text-left">
            <label for="lastName">
              {{ $t('login.new-presentation.last-name') }}*
            </label>
            <input
              class="form-control"
              name="lastName"
              :placeholder="$t('login.new-presentation.last-name')"
              required
              type="Text"
              v-model="primaryContact.lastname">
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6 pr-md-2 text-left">
            <label for="title">
              {{ $t('login.new-presentation.title') }}
            </label>
            <input
              class="form-control"
              name="title"
              :placeholder="$t('login.new-presentation.title')"
              type="Text"
              v-model="primaryContact.title">
          </div>

          <div class="form-group col-md-6 pl-md-2 text-left">
            <label for="phoneNumber">
              {{ $t('login.new-presentation.phone') }}*
            </label>
            <input
              class="form-control"
              name="phoneNumber"
              :placeholder="$t('login.new-presentation.phone')"
              required
              type="Text"
              v-model="primaryContact.phone">
          </div>
        </div>

        <div class="form-group my-3 d-flex justify-content-end">
          <a
            class="cta cta--border small reset-status ra-gtm-event"
            data-gtm-section="Presentation Login"
            data-gtm-label="Go back"
            href="javascript:void(0)"
            v-show="isANewCompany"
            @click="resetStatus">
            {{ $t('common.go-back') }}
            <i class="fa fa-rotate-left"></i>
          </a>

          <button
            class="cta cta--border small ra-gtm-event"
            data-gtm-section="Presentation Login"
            data-gtm-label="Save Contact"
            href="javascript:void(0)"
            type="submit"
            v-show="isANewCompany"
            @click="submitPrimaryForm('insert', primaryContact)">
            <template v-if="isLoading">
            {{ $t('common.actions.saving') }}
              <i class="fa fa-spinner fa-pulse"></i>
            </template>
            <template v-else>
              {{ $t('common.actions.save') }}
              <i class="fa fa-save"></i>
            </template>
          </button>

          <button
            class="cta cta--border small ra-gtm-event"
            data-gtm-section="Presentation Login"
            data-gtm-label="Update Contact"
            href="javascript:void(0)"
            type="submit"
            v-show="!isANewCompany"
            @click="submitPrimaryForm('update', primaryContact)">
            <template v-if="isLoading">
            {{ $t('common.actions.updating') }}
              <i class="fa fa-spinner fa-pulse"></i>
            </template>
            <template v-else>
              {{ $t('common.actions.update') }}
              <i class="fa fa-rotate-left"></i>
            </template>
          </button>
        </div>
      </div>
      <!-- // Primary Contact Form -->
    </form>

    <!-- * Additional Contacts -->
    <div class="additional-contacts js-additional-contacts">
      <h5 class="divider l-spacing-2 pb-1">
        {{ $t('login.new-presentation.additional-members') }}
      </h5>
      <div class="addContactArea">
        <!-- Single member item -->
        <MemberForm
          v-for="(contact, idx) in companyTeam"
          class="js-edit-contact-form"
          :data="contact"
          formAction="update"
          :idx="idx + 1"
          :key="contact.id"
          :loading="isLoading"
          @submit="openCompanyDetail(currentCompany)"
          @delete-contact="openCompanyDetail(currentCompany)" />
        <!-- // Single member item -->
        <!-- Create New Member -->
        <MemberForm
          v-show="isCreatingContact"
          :data="temporaryData"
          formAction="insert"
          :idx="companyTeam.length + 1"
          :loading="isLoading"
          @hide-form="isCreatingContact = false"
          @submit="openCompanyDetail(currentCompany)" />
        <!-- // Create New Member -->
      </div>
      <!-- * Add Team Member -->
      <div class="add-teammember">
        <a
          v-show="!isCreatingContact"
          class="member-button cta p-3 cta--grey ra-gtm-event"
          data-gtm-section="Presentation Login"
          data-gtm-label="Add team member"
          href="javascript:void(0)"
          @click="isCreatingContact = true">
          {{ $t('login.new-presentation.add-member') }}
        </a>
      </div>
      <!-- // * Add Team Member -->
      <div class="divider l-spacing-2 mb-4 pb-1"></div>

    </div>
    <!-- // * Additional Contacts -->

    <button
      type="button"
      :class="['cta', 'cta--start', 'ra-gtm-event',
      { 'disabled': isButtonDisabled }]"
      data-gtm-section="Presentation Login"
      data-gtm-label="Start Presentation"
      ref="startButton"
      @click="$emit('close-modal')">
        {{ $t('login.new-presentation.start-presentation') }}
    </button>
  </div>
</template>

<script>
import 'parsleyjs';
import $ from 'jquery';
import axios from 'axios';
import { mapState } from 'vuex';

import * as endpoints from '@/common/endpoints';
import { loginMixins } from '@/common/mixins';

import MemberForm from './MemberForm.vue';

export default {
  name: 'New Presentation',
  mixins: [loginMixins],
  components: {
    MemberForm,
  },
  emits: ['close-modal'],
  data() {
    return {
      companyList: '',
      currentCompany: '',
      companyTeam: [],
      primaryContact: {
        email: '',
        firstname: '',
        lastname: '',
        title: '',
        phone: '',
      },
      temporaryData: {
        email: '',
        firstname: '',
        lastname: '',
        title: '',
        phone: '',
      },
      isANewCompany: false,
      isButtonDisabled: true,
      isCreatingContact: false,
      isLoading: false,
    };
  },
  mounted() {
    // Disable main button
    this.isButtonDisabled = true;
  },
  methods: {
    showDropdown() {
      if (!this.isANewCompany) {
        axios.post(
          `${endpoints.BASEAPIURL}searchfunction${endpoints.EXT}`,
          {
            term: this.currentCompany,
            login: this.loggedin,
          },
        )
          .then((response) => {
            const json = response.data;

            if (json.reply === 1) {
              this.companyList = json.response;
              $('#primaryForm .input-drop-down').slideDown();
            } else {
              console.error(json.reply);
              console.error(json.message);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    hideDropdown() {
      $('#primaryForm .input-drop-down').slideUp();
    },
    resetStatus() {
      this.isANewCompany = false;
      this.clearMainContactData();
      $('.primary-contact').slideUp();
      $('#primaryForm').parsley().reset();
    },
    createNewCompany() {
      $('#primaryForm .input-drop-down').slideUp();
      this.clearMainContactData();
      this.parsleyTheForm('#primaryForm');
      this.isANewCompany = true;
      this.isButtonDisabled = true;

      $('.primary-contact').slideDown().removeAttr('id');
      $('.additional-contacts').slideUp();
    },
    openCompanyDetail(payload) {
      $('#primaryForm .input-drop-down').slideUp();

      // Enable Button
      this.isButtonDisabled = false;

      // Reset Parsley validations
      $('#primaryForm').parsley().reset();

      // Show company Name
      $('.js-company-name').slideDown();
      $('.primary-contact').slideDown();

      axios.post(
        `${endpoints.BASEAPIURL}presentationfunctions${endpoints.EXT}`,
        {
          action: 'results',
          company: payload,
          login: this.loggedin,
        },
      )
        .then((response) => {
          const { data } = response;
          this.currentCompany = payload;

          if (data.reply === 1) {
            this.companyTeam = [];

            data.result.map((contact) => {
              if (contact.primarycontact === 1) {
                this.primaryContact = contact;
              } else {
                this.companyTeam.push({ ...contact });
              }
              return true;
            });
            $('.js-additional-contacts').slideDown();

            // Populate Company name
            this.$store.commit('fillCompanyName', this.currentCompany);
            // Populate Company Contact info
            this.$store.commit('fillCompanyData', this.primaryContact);
            this.$store.commit('addToCompanyTeam', this.companyTeam);
          } else {
            console.error(data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    /**
     * @param {string} action insert, update.
     * @param {object} formData Data to be submitted on the form
     */
    submitPrimaryForm(action, formData) {
      $('#primaryForm').parsley().on('form:submit', () => {
        this.isLoading = true;
        axios.post(
          `${endpoints.BASEAPIURL}presentationfunctions${endpoints.EXT}`,
          {
            action,
            ...formData,
            // Added conditionally if action is insert
            ...(action === 'insert' && {
              company: this.currentCompany,
              primarycontact: 1,
            }),
            login: this.loggedin,
          },
        )
          .then((response) => {
            console.log(response);
            const json = response.data;
            if (json.reply === 1) {
              this.isLoading = false;

              if (action === 'insert') {
                this.openCompanyDetail(this.currentCompany);
                this.isCreatingContact = false;
                this.isANewCompany = false;
              }
            } else {
              console.error(json.message);
            }
          })
          .catch((error) => {
            console.error(error);
          });
        return false;
      });
    },
    clearMainContactData() {
      this.currentCompany = '';
      this.primaryContact = {
        email: '',
        firstName: '',
        lastName: '',
        title: '',
        phone: '',
      };
    },
  },
  computed: {
    ...mapState([
      'loggedin',
    ]),
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/new-presentation.scss';
</style>
