<template>
  <div v-if="rawData">
    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ getData.title }}
        </h2>
      </div>

      <div class="section__content">
        <div class="row justify-content-center align-items-end">
          <div
            :class="['print-tab', {
              'active': currentMode === 1,
              'hidden': !hasModes(1)}]"
            @click="currentMode = 1">
            <h3 class="title title--medium">
              Performance Mode
            </h3>
            <div class="sub-heading">
              <span>
                2 drops per pixel
              </span>

              <div class="wrap-drops">
                <div class="drop"></div>
                <div class="drop"></div>
              </div>
            </div>
          </div>

          <div
            :class="['print-tab', {
              'active': currentMode === 2,
              'hidden': !hasModes(2)}]"
            @click="currentMode = 2">
            <h3 class="title title--medium">
              Gamut-Performance Mode
            </h3>
            <div class="sub-heading">
              <span>
                2.5 drops per pixel
              </span>

              <div class="wrap-drops">
                <div class="drop"></div>
                <div class="drop">
                  <div class="drop drop--small"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center align-items-end">
          <template v-for="(group, id) in getData.print_modes" :key="id">
            <div class="col-md-6">
              <div class="print-mode-group">
                <h4 class="title title--small">
                  {{ group.ng_value }}
                </h4>

                <div class="wrap-print-modes">
                  <div class="row">
                    <div class="col-sm-6" v-for="(mode, idx) in group.modes" :key="idx">
                      <div class="print-mode">
                        <div class="print-mode__img">
                          <img :src="mode.image" alt="">
                          <div class="speed" v-html="mode.speed"></div>
                        </div>
                        <div class="print-mode__text">
                          <div class="print-mode__colors">
                            <div class="wrap-colors" v-html="mode.colors"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ShareButton
              v-if="getData.shareable_pdf"
              :hash="hashify(`${$t('main-nav.technology')} - ${$t('technology.submenu.print-modes')} ${printModeName} ${printerName}`)"
              :shareLabel="`${$t('main-nav.technology')} - ${$t('technology.submenu.print-modes')} ${printModeName} ${printerName}`"
              :shareLink="`${cmsURL}assets/${getData.shareable_pdf}`"
              @share-this="shareThis" />

          </template>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'PrintModes',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton,
  },
  data() {
    return {
      currentMode: 1,
      rawData: null,
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/technology_print_modes`, {
      params: {
        fields: '*,translations.*,mode.*,printer.printers_id',
      },
    })
      .then(({ data: { data } }) => {
        this.rawData = data;
      });
  },
  mounted() {
    this.showMainTabs();
  },
  // reset the performance mode afeter changing between printers
  watch: {
    currentPrinter() {
      this.currentMode = 1;
    },
  },
  computed: {
    info() {
      if (this.rawData) {
        return this.rawData.filter((item) => item.mode.id === this.currentMode);
      }

      return false;
    },
    hasModes() {
      return (mode) => this.rawData.filter(
        (item) => item.printer.some(
          (printer) => printer.printers_id === this.currentPrinter,
        ),
      ).some((item) => item.mode.id === mode);
    },
    printModeName() {
      return (this.currentMode === 1) ? 'Performance' : 'Gamut-Performance';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/technology/printModes.scss';
</style>
