<template>
  <div>
    <div
      class="wrap-printer"
      :class="wrapperClass">
      <img :src="data.software_printer" alt="">

      <div class="wrap-callouts">
        <template
          v-for="(item, idx) in data.software"
          :key="idx">
          <div
            v-if="item.is_blue_box"
            class="box box--copy"
            data-toggle="modal"
            :data-target="`#modal-software-${idx + 1}`">
            <p>
              {{ item.headline }}
            </p>
            <i>
              {{ item.subheadline }}
            </i>
          </div>

          <div
            v-else
            class="single-callout"
            :class="`single-callout--${idx + 1}`"
            data-toggle="modal"
            :data-target="`#modal-software-${idx + 1}`">
            <div class="box box--icon">
              <img :src="item.image" :alt="item.headline">
            </div>
            <h3>
              {{ item.headline }}
            </h3>
          </div>
        </template>

        <!-- Additional content -->
        <div class="additional--icon">
          <img src="images/workflow/t470/icon-printedrolls.png" alt="Printed Rolls">
          <h4>
            Printed Rolls
          </h4>
        </div>

        <div v-if="currentPrinter === 3" class="additional--icon-bottom">
          <img src="images/workflow/t700/printed-sheets-icon.png" alt="Printed Rolls">
          <h4>
            Printed Sheets
          </h4>
        </div>

        <div class="wrap-arrow wrap-arrow--top">
          <span v-if="currentPrinter === 1">
            LAMINATOR PLAN
          </span>
          <span v-else-if="currentPrinter === 2">
            CORRUGATOR PLAN
          </span>
          <span v-else>
            PRODUCTION PLAN
          </span>
          <div class="arrow"></div>
        </div>

        <div class="wrap-arrow wrap-arrow--top-left">
          <div class="arrow"></div>
          <span v-if="currentPrinter === 3">
            DIGITAL PRINT PLAN
          </span>
          <span v-else>
            DIGITAL PREPRINT PLAN
          </span>
        </div>

        <div class="wrap-arrow wrap-arrow--bottom-left">
          <span>
            PRINT-READY PDFs
          </span>
          <div class="arrow"></div>
        </div>

        <div class="wrap-arrow wrap-arrow--top-right wrap-arrow--top-right-sm">
          <div class="arrow"></div>
        </div>

        <div v-if="currentPrinter === 3" class="wrap-arrow wrap-arrow--bottom-right">
          <div class="arrow"></div>
        </div>

        <div class="wrap-arrow wrap-arrow--bottom">
          <div class="arrow"></div>
        </div>

        <div class="wrap-arrow wrap-arrow--bottom-2">
          <div class="arrow"></div>
        </div>

      </div>
    </div>
    <template v-if="currentPrinter !== 3">
      <div class="software-content">
        <h4 class="title title--xsmall">
          Increase the production capacity and flexibility throughout your high-graphics plant with HP’s complete digital corrugated workflow ecosystem.
        </h4>
        <ul>
          <li>
            Lower costs, reduce time in the printing process, and improve the efficiency of downstream processes.
          </li>
          <li>
            Eliminate the need for inventory and deliver supply chain agility with fast turnaround time and Just-In-Time production.
          </li>
          <li>
            Address the customer trend for short runs with efficient production of jobs shorter than one roll.
          </li>
          <li>
            Introduce multi-job rolls into the production system by grouping and batching jobs.
          </li>
        </ul>
      </div>
    </template>
    <template v-else>
      <div class="software-content">
        <h4 class="title title--xsmall">
          Increase production capacity and flexibility with HP’s complete digital workflow ecosystem.
        </h4>
        <ul>
          <li>
            Lower costs, reduce time in the printing process, and improve the efficiency of downstream processes.
          </li>
          <li>
            Eliminate the need for inventory and deliver supply chain agility with fast turnaround time and Just-In-Time production.
          </li>
          <li>
            Address the customer trend for short runs with efficient production of jobs shorter than one roll.
          </li>
          <li>
            Introduce multi-job rolls into the production system without negatively impacting down-stream processes by integrating with the existing factory management and planning systems.
          </li>
        </ul>
      </div>
    </template>

    <!-- Modals -->
    <template
      v-for="(item, idx) in data.software"
      :key="idx">
      <Modal
        :idModal="`modal-software-${idx + 1}`">
        <div class="wrap-modal-software">
          <div class="container">
            <div class="intro">
              <div class="intro__icon">
                <img :src="item.image" :alt="item.headline">
              </div>
              <div class="intro__content">
                <h3 class="title title--medium">
                  {{ item.headline }}
                </h3>

                <div v-html="item.content"></div>
              </div>
            </div>

            <div
              v-if="item.partners.length"
              class="partners">
              <h5 class="title title--small title--blue">
                Partners
              </h5>

              <div
                v-for="(partner, index) in item.partners"
                :key="index"
                class="single-partner">
                <img :src="partner.logo" alt="" class="logo">

                <div v-html="partner.content"></div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </template>
  </div>
</template>

<script>
import { defaultMixins } from '@/common/mixins';

import Modal from '@/components/modal/Modal.vue';

export default {
  name: 'One Package Software',
  mixins: [defaultMixins],
  props: {
    data: Object,
  },
  components: {
    Modal,
  },
  data() {
    return {
      currentSlide: 0,
    };
  },
  computed: {
    wrapperClass() {
      return (this.currentPrinter === 1) ? 'wrap-printer--t470' : 'wrap-printer--t1190';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/e2e-solutions/workflow/software';
  @import '@/scss/views/e2e-solutions/workflow/software-modal';
</style>
