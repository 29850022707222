<template>
  <div class="sustainability-intro">
    <div class="container-xl">
      <div class="section__title text-center">
        <h2 class="title">
          {{ $t('main-nav.sustainability') }}
        </h2>
      </div>

      <template v-if="info">
          <div class="section__content container-xl">
            <div class="row">
                <div class="col-md-5 offset-md-1">
                  <h1 class="title" v-html="getData.headline">
                  </h1>
                  <p class="mb-4" v-html="getData.content"></p>
                </div>
            </div>
          </div>

          <div class="intro__bg">
            <img :src="`${cmsURL}assets/${getDataByPrinter.background}`" />
          </div>
      </template>
    </div>

    <!-- <ShareButton
      :hash="hashify(`Sustainability intro ${currentPrinter}`)"
      :shareLabel="`${$t('main-nav.sustainability')} - ${$t('sustainability.submenu.intro')}`"
      :shareLink="`${baseURL}${getDataByPrinter.video_url}`"
      @share-this="shareThis" /> -->
  </div>
</template>

<script>
import axios from 'axios';

import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

// import ShareButton from '@/components/ui/ShareButton.vue';

export default {
  name: 'Intro',
  mixins: [defaultMixins, filters],
  components: {
    // ShareButton,
  },
  data() {
    return {
      baseURL: endpoints.BASEURL,
      cmsURL: endpoints.CMS,
      info: null,
    };
  },
  created() {
    axios.get(`${endpoints.CMS}items/sustainability_intro`, {
      params: {
        fields: '*,translations.*,printer.printers_id',
      },
    })
      .then(({ data: { data } }) => {
        this.info = data;
      });
  },
  mounted() {
    this.showMainTabs();
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/sustainability/intro';
</style>
