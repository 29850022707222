<template>
  <aside class="sidebar-nav js-sidebar">
    <div class="sidebar-nav__contact d-flex mb-3">
      <div class="sidebar-nav__headshot mr-2">
        <img
          class="object-fit-cover-center"
          :src="salesPerson.photo || 'images/headshot.jpg'" />
      </div>
      <div>
        <h6 class="m-0">
          {{ salesPerson.firstname }} {{ salesPerson.lastname }}
        </h6>
        <p class="small m-0" id="salesTitle">
          {{ salesPerson.title }}
        </p>
      </div>
    </div>
    <div class="sidebar-nav__list">
      <router-link
        class="ra-gtm-event"
        href="javascript:void(0)"
        data-gtm-section="About Us"
        data-gtm-subsection=""
        data-gtm-label="Click Navigation"
        to="/about-us"
        @click="closeSidebar(); clickTracked($event)">
        {{ $t("main-sidebar.about-us") }}
      </router-link>
      <router-link
        class="ra-gtm-event"
        href="javascript:void(0)"
        data-gtm-section="Meet the Team"
        data-gtm-subsection=""
        data-gtm-label="Click Navigation"
        to="/team"
        @click="closeSidebar(); clickTracked($event)">
        {{ $t("main-sidebar.meet-team") }}
      </router-link>
      <router-link
        class="ra-gtm-event"
        href="javascript:void(0)"
        data-gtm-section="Next Steps"
        data-gtm-subsection=""
        data-gtm-label="Click Navigation"
        to="/next-steps"
        @click="closeSidebar(); clickTracked($event)">
        {{ $t("main-sidebar.next-steps") }}
      </router-link>
      <a
        href="javascript:void(0)"
        :class="['dropdown-toggler', {'active': areLinksVisible}]"
        @click="toggleLinks">
        {{ $t("main-sidebar.other-links") }} <i class="fa fa-angle-down"></i>
      </a>
      <div class="dropdown-content" v-show="areLinksVisible">
        <a
          class="ra-gtm-event newpresentation"
          href="javascript:void(0)"
          data-toggle="modal"
          data-target="#loginModal"
          data-gtm-section="Sidebar Menu"
          data-gtm-subsection=""
          data-gtm-label="Click New Presentation"
          @click="newPresentation(); clickTracked($event)">
          {{ $t("main-sidebar.new-presentation") }}
        </a>
        <a
          class="ra-gtm-event"
          href="https://www.office.com/launch/onenote"
          target="_blank"
          data-gtm-section="Sidebar Menu"
          data-gtm-subsection=""
          data-gtm-label="Click My Notes Link"
          @click="clickTracked($event)">
          {{ $t("main-sidebar.notes") }}
        </a>
        <router-link
          class="ra-gtm-event"
          href="javascript:void(0)"
          data-gtm-section="My Profile"
          data-gtm-subsection=""
          data-gtm-label="Click Navigation"
          to="/profile"
          @click="closeSidebar(); clickTracked($event)">
         {{ $t("main-sidebar.profile") }}
        </router-link>
        <router-link
          class="ra-gtm-event"
          href="javascript:void(0)"
          data-gtm-section="Tool Feedback"
          data-gtm-subsection=""
          data-gtm-label="Click Navigation"
          to="/feedback"
          @click="closeSidebar(); clickTracked($event)">
          {{ $t("main-sidebar.feedback") }}
        </router-link>
        <a
          v-show="salesPerson.superadmin"
          class="ra-gtm-event"
          href="performance/"
          target="_blank"
          data-gtm-section="Sidebar Menu"
          data-gtm-subsection=""
          data-gtm-label="Click Performance">
          {{ $t("main-sidebar.performance") }}
        </a>

      </div>
    </div>
  </aside>
</template>

<script>
import { defaultMixins } from '@/common/mixins';
import router from '@/router/index';

export default {
  name: 'MainSidebar',
  mixins: [defaultMixins],
  data() {
    return {
      areLinksVisible: false,
    };
  },
  methods: {
    closeSidebar() {
      this.$parent.isSidebarOpen = false;
    },
    newPresentation() {
      router.push('/');
      this.$emit('new-presentation');
      this.$parent.isSidebarOpen = false;
    },
    toggleLinks() {
      this.areLinksVisible = !this.areLinksVisible;
    },
  },
};
</script>

<style lang="scss">
  @import '@/scss/components/mainSidebar.scss';
</style>
