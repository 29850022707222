<template>
  <div class="virtual-tour__360 bg">
    <!-- eslint-disable max-len -->
    <button class="hamburger--collapse is-active close js-close-360"
      type="button"
      data-dismiss="modal"
      aria-label="Close"
      @click="closeTour">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>

    <img :src="initialImage" alt="" class="tour-360__img js-tour-360-img">

    <img src="images/virtual-tour/drag-icon.png" alt="" class="tour-360__icon js-tour-360-icon">

    <div class="tour-360__drag" id="js-drag"></div>

    <div class="tour-360__zoom" id="js-zoom">
      <div class="line js-zoom-line">
        <div class="inner"></div>
      </div>
      <div class="handler js-zoom-handler"></div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import $ from 'jquery';

import { defaultMixins } from '@/common/mixins';

export default {
  name: 'Tour360',
  mixins: [defaultMixins],
  emits: ['close'],
  data() {
    return {
      movie: {
        frame: 1,
      },
      zoom: 1,
    };
  },
  mounted() {
    gsap.registerPlugin(Draggable);
    gsap.registerPlugin(CSSRulePlugin);

    const thisComponent = this;

    /**
     * 360 Rotation view
     */
    const spriteslider = document.getElementById('js-drag');
    // document.body.appendChild(spriteslider);
    // spriteslider.slider = document.getElementById('js-drag');
    spriteslider.spritecount = 36;
    spriteslider.pixelsperincrement = 36;
    spriteslider.multiplier = 1;
    spriteslider.lastmultiplier = 1;

    // Drag view
    Draggable.create(spriteslider, {
      type: 'x',
      trigger: spriteslider,
      bounds: {
        minX: 0,
        maxX: 0,
      },
      edgeResistance: 0,
      zIndex: 20,
      zIndexBoost: false,

      onDragStart() {
        $('.js-tour-360-icon').fadeOut();
      },
      onDrag() {
        if (this.isDragging) {
          const t = this.target;
          // eslint-disable-next-line max-len
          t.multiplier = (Math.floor(this.x / t.pixelsperincrement) + t.lastmultiplier + t.spritecount) % t.spritecount;
          thisComponent.movie.frame = Math.round(t.multiplier);
          thisComponent.updateHandler();
        }
      },
      onDragEnd() {
        $('.js-tour-360-icon').fadeIn();
        const t = this.target;
        t.lastmultiplier = t.multiplier % t.spritecount;
      },
    });

    // Zoom In - out
    Draggable.create('.js-zoom-handler', {
      type: 'y',
      bounds: document.getElementById('js-zoom'),
      zIndex: 30,
      zIndexBoost: false,

      onDragStart() {
        $('.js-tour-360-icon').fadeOut();
      },
      onDrag() {
        const actualPos = (this.y * -1);
        const zoomVal = 100 + (actualPos * 100) / 186;
        thisComponent.zoom = zoomVal.toFixed(0);

        $('.js-tour-360-img').css({
          width: `${thisComponent.zoom}%`,
          height: `${thisComponent.zoom}%`,
        });

        gsap.timeline().set('.js-zoom-line .inner', {
          height: actualPos,
        });
      },
    });
  },
  methods: {
    closeTour() {
      this.$emit('close');
    },
    restoreView() {
      gsap.set(this.movie, {
        duration: 2,
        frame: 1,
        roundProps: 'frame',
        onUpdate: this.updateHandler,
      });
    },
    updateHandler() {
      const printerFolder = {
        1: 't470s',
        2: 't1190',
        3: 't700',
      };

      $('.js-tour-360-img').attr('src', `images/virtual-tour/360/${printerFolder[this.currentPrinter]}/${this.movie.frame}.png`);
    },
  },
  computed: {
    initialImage() {
      const printerFolder = {
        1: 't470s',
        2: 't1190',
        3: 't700',
      };

      return `images/virtual-tour/360/${printerFolder[this.currentPrinter]}/0.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/virtual-tour/tour-360.scss';
</style>
