import { createRouter, createWebHistory } from 'vue-router';

// Main Sections
import CustomerStories from '@/views/CustomerStories.vue';
import Intro from '@/views/Intro.vue';
import Library from '@/views/Library.vue';
import PartnerSolutions from '@/views/PartnerSolutions.vue';
import E2ESolutions from '@/views/E2ESolutions.vue';
import Technology from '@/views/Technology.vue';
import Sustainability from '@/views/Sustainability.vue';
import VirtualTour from '@/views/VirtualTour.vue';
import WhyDigital from '@/views/WhyDigital.vue';

// Sidebar Sections
import About from '@/views/About.vue';
import Feedback from '@/views/Feedback.vue';
import NextSteps from '@/views/NextSteps.vue';
import Profile from '@/views/Profile.vue';
import Team from '@/views/Team.vue';

// Technology Sub Sections
import TechnicalSpecs from '@/views/technology/TechnicalSpecs.vue';
import HPTIJ from '@/views/technology/HPTIJ.vue';
import PrintModes from '@/views/technology/PrintModes.vue';
import BrilliantInk from '@/views/technology/BrilliantInk.vue';
import PaperPath from '@/views/technology/PaperPath.vue';

// Why Digital Sub Sections REPLACE ITEMS
import DigitalValueProposition from '@/views/why-digital/DigitalValueProposition.vue';
import Customers from '@/views/why-digital/Customers.vue';

// End to End Solutions Sub Sections
import Workflow from '@/views/e2e-solutions/Workflow.vue';
import ServiceSupport from '@/views/e2e-solutions/ServiceSupport.vue';

// Partner Solutions Sub Sections
import Hardware from '@/views/partner-solutions/Hardware.vue';
import Software from '@/views/partner-solutions/Software.vue';

// Sustainability Sub Sections
import SustainabilityIntro from '@/views/sustainability/SustainabilityIntro.vue';
import SustainabilityCircle from '@/views/sustainability/SustainabilityCircle.vue';
import SustainabilityVideo from '@/views/sustainability/SustainabilityVideo.vue';

// Technology Routes
const technologyRoutes = [
  {
    path: '',
    component: TechnicalSpecs,
  },
  {
    path: 'technical-specs',
    component: TechnicalSpecs,
  },
  {
    path: 'hp-tij',
    component: HPTIJ,
  },
  {
    path: 'print-modes',
    component: PrintModes,
  },
  {
    path: 'ink',
    component: BrilliantInk,
  },
  {
    path: 'paper-path',
    component: PaperPath,
  },
];

// Why Digital Routes REPLACE ITEMS
const whyDigitalRoutes = [
  {
    path: '',
    component: DigitalValueProposition,
  },
  {
    path: 'digital-value-proposition',
    component: DigitalValueProposition,
  },
  {
    path: 'customers',
    component: Customers,
  },
];

// Partner Solutions Routes
const partnerSolutionsRoutes = [
  {
    path: '',
    component: Software,
  },
  {
    path: 'hardware',
    component: Hardware,
  },
  {
    path: 'software',
    component: Software,
  },
];

// End to End Solutions
const e2eRoutes = [
  {
    path: '',
    component: Workflow,
  },
  {
    path: 'workflow',
    component: Workflow,
  },
  {
    path: 'service-and-support',
    component: ServiceSupport,
  },
];

// Sustainability
const sustainabilityRoutes = [
  {
    path: '',
    component: SustainabilityIntro,
  },
  {
    path: 'sustainability-intro',
    component: SustainabilityIntro,
  },
  {
    path: 'sustainability-video',
    component: SustainabilityVideo,
  },
  {
    path: 'sustainability-circle',
    component: SustainabilityCircle,
  },
];

const mainRoutes = [
  // Main Nav Routes
  {
    path: '/',
    name: 'Home',
    component: Intro,
  },
  {
    path: '/intro',
    name: 'Intro',
    component: Intro,
  },
  {
    path: '/why-digital',
    name: 'WhyDigital',
    component: WhyDigital,
    children: [
      ...whyDigitalRoutes,
    ],
  },
  {
    path: '/virtual-tour',
    name: 'VirtualTour',
    component: VirtualTour,
  },
  {
    path: '/technology',
    name: 'Technology',
    component: Technology,
    children: [
      ...technologyRoutes,
    ],
  },
  {
    path: '/partner-solutions',
    name: 'PartnerSolutions',
    component: PartnerSolutions,
    children: [
      ...partnerSolutionsRoutes,
    ],
  },
  {
    path: '/sustainability',
    name: 'Sustainability',
    component: Sustainability,
    children: [
      ...sustainabilityRoutes,
    ],
  },
  {
    path: '/e2e-solutions',
    name: 'E2ESolutions',
    component: E2ESolutions,
    children: [
      ...e2eRoutes,
    ],
  },
  {
    path: '/customer-stories',
    name: 'CustomerStories',
    component: CustomerStories,
  },
  {
    path: '/library',
    name: 'Library',
    component: Library,
  },
];

const sidebarRoutes = [
  // Sidebar Nav Routes
  {
    path: '/about-us',
    name: 'AboutUs',
    component: About,
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
  },
  {
    path: '/next-steps',
    name: 'NextSteps',
    component: NextSteps,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback,
  },
];

const locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1');

const router = createRouter({
  base: (locale.trim().length && locale !== '/') ? `/${locale}` : undefined,
  history: createWebHistory(),
  routes: [
    ...mainRoutes,
    ...sidebarRoutes,
  ],
});

export default router;
