<template>
  <div>
    <template
      v-for="(slide, idx) in data.preprint_scenarios"
      :key="idx">
      <div
        v-if="currentSlide === idx"
        class="preprint__intro">
        <div class="content">
          <h4>
            {{ slide.headline }}
          </h4>
          <div v-html="slide.description"></div>
        </div>
        <div class="images">
          <img
            v-for="(item, id) in slide.intro_images"
            :key="id"
            :src="item.image"
            alt="">
        </div>
      </div>
    </template>
    <div class="preprint-arrows">
      <div class="preprint-arrow-single preprint-arrow--left">
        <p>
          Print direction
        </p>
      </div>
      <div class="preprint-arrow-single preprint-arrow--right">
        <p>
          Corrugation direction
        </p>
      </div>
    </div>
    <div class="preprint__carousel">
      <div
        id="preprint-carousel"
        class="carousel slide"
        data-ride="carousel"
        data-interval="0">
        <div class="carousel-inner">

          <div
            v-for="(slide, idx) in data.preprint_scenarios"
            :key="idx"
            class="carousel-item single-slide"
            :class="{'active': idx === 0}">
            <img class="d-block w-100" :src="slide.main_image" :alt="slide.headline">
          </div>
        </div>

        <div class="carousel__controls">
          <a class="carousel-control carousel-control--prev" href="#preprint-carousel" role="button" data-slide="prev">
            <svg aria-hidden="true" role="img" class="arrow" width="32" height="32" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m10 19l-7-7m0 0l7-7m-7 7h18"></path>
            </svg>
            <span class="sr-only">Previous</span>
          </a>

          <ol class="carousel-indicators">
            <li
              v-for="(slide, idx) in data.preprint_scenarios"
              :key="idx"
              data-target="#preprint-carousel"
              :data-slide-to="idx"
              :class="{'active': idx === 0}"></li>
          </ol>

          <a class="carousel-control carousel-control--next" href="#preprint-carousel" role="button" data-slide="next">
            <svg aria-hidden="true" role="img" class="arrow" width="32" height="32" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14 5l7 7m0 0l-7 7m7-7H3"></path>
            </svg>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'DigitalPreprint',
  props: {
    data: Object,
  },
  data() {
    return {
      currentSlide: 0,
    };
  },
  mounted() {
    $('#preprint-carousel').on('slide.bs.carousel', (e) => {
      this.currentSlide = e.to;
    });
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/e2e-solutions/workflow/digital-preprint.scss';
</style>
