<template>
  <SubsectionNavBottom
    class="section--top sustainability"
    parent="Sustainability"
    :sidebar="sidebar"
    :subSections="subSections">
    <router-view></router-view>
  </SubsectionNavBottom>
</template>

<script>
import { defaultMixins, filters } from '@/common/mixins';

import SubsectionNavBottom from '@/layout/SubsectionNavBottom.vue';

export default {
  name: 'Sustainability',
  mixins: [defaultMixins, filters],
  props: {
    sidebar: Boolean,
  },
  components: {
    SubsectionNavBottom,
  },
  computed: {
    subSections() {
      /**
       * The url key inside each element MUST match the router path
       * see: @/router/index.js
       */
      return [
        {
          label: this.$i18n.t('sustainability.submenu.intro'),
          url: '',
          shareLabel: 'Sustainability Intro',
        },
        {
          label: this.$i18n.t('sustainability.submenu.circle'),
          url: 'sustainability-circle',
          shareLabel: 'Circle of Sustainability',
        },
      ];
    },
  },
};
</script>
