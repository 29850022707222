<template>
  <SubsectionNavBottom
    class="section--top partner-solutions"
    parent="Partner Solutions"
    :sidebar="sidebar"
    :subSections="subSections">
    <router-view></router-view>
  </SubsectionNavBottom>
</template>

<script>
import { filters } from '@/common/mixins';

import SubsectionNavBottom from '@/layout/SubsectionNavBottom.vue';

export default {
  name: 'PartnerSolutions',
  mixins: [filters],
  props: {
    sidebar: Boolean,
  },
  components: {
    SubsectionNavBottom,
  },
  computed: {
    subSections() {
      /**
       * The url key inside each element MUST match the router path
       * see: @/router/index.js
       */
      return [
        {
          label: this.$i18n.t('partner-solutions.submenu.software'),
          shareLabel: 'Software',
          url: '',
        },
        {
          label: this.$i18n.t('partner-solutions.submenu.hardware'),
          shareLabel: 'Hardware',
          url: 'hardware',
        },
      ];
    },
  },
};
</script>
