<template>
  <div class="tij-printheads">
    <div class="row">
      <div class="col-md-6">
          <div class="printheads__image">
            <img :src="`${cmsURL}assets/${data.printheads_image}`" alt="">
          </div>
      </div>
      <div class="col-md-6">
        <div class="printheads__content" v-html="data.printheads_content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultMixins } from '@/common/mixins';

export default {
  name: 'Printheads',
  mixins: [defaultMixins],
  props: {
    data: Object,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/technology/tij/printheads.scss';
</style>
