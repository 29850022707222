<template>
  <div class="why-hp-digital">
    <h4 class="title ml-xl-5 title--medium">
      A digital solution is only as good as the sum of its parts
    </h4>
    <div class="why-hp-digital__content">
      <div class="why-hp-digital__content-left">
        <img src="images/why-digital/digital-value/why-hp-labels.png" alt="">
        <div class="why-hp-digital__left-cards">
          <div class="why-hp-digital__left-cards-single-card">
            <h4 class="title title--small title--blue">
              HP has a global footprint and is #1
            </h4>
            <p>
              in 4 packaging segments
            </p>
          </div>
          <div
            class="why-hp-digital__left-cards-single-card why-hp-digital__left-cards-single-card--long">
            <h4 class="title title--small title--blue">
              Broadest Portfolio
            </h4>
            <p>
              of digital solutions for paper-based packaging
            </p>
          </div>
          <div class="why-hp-digital__left-cards-single-card">
            <h4 class="title title--small title--blue">
              Strategic Partner
            </h4>
            <p>
              that’s vertically integrated for end-to-end digital solution development
            </p>
          </div>
          <div
            class="why-hp-digital__left-cards-single-card why-hp-digital__left-cards-single-card--reverse why-hp-digital__left-cards-single-card--long">
            <h4 class="title title--small title--blue">
              Global Brands
            </h4>
            <p>
              Partner in educating and developing relationships with
            </p>
          </div>
        </div>
      </div>
      <div class="why-hp-digital__content-right">
        <img src="images/why-digital/digital-value/why-hp-digital.png" alt="">
        <p class="whd-inner-content whd-inner-content--1">
          Ink
        </p>
        <p class="whd-inner-content whd-inner-content--2">
          Presses
        </p>
        <p class="whd-inner-content whd-inner-content--3">
          Image Processing
        </p>
        <p class="whd-inner-content whd-inner-content--4">
          Paper & Board Transport
        </p>
        <p class="whd-inner-content whd-inner-content--5">
          Dryers
        </p>
        <p class="whd-inner-content whd-inner-content--6">
          Printheads
        </p>
        <p class="whd-inner-content whd-inner-content--7">
          Solution innovations
        </p>
        <p class="whd-inner-content whd-inner-content--8">
          Print <br>Servers
        </p>
        <p class="whd-inner-content whd-inner-content--9">
          Service & Support
        </p>
        <p class="whd-inner-content whd-inner-content--10">
          Software & Firmware
        </p>
        <p class="whd-inner-content whd-inner-content--11">
          Business Transformation Services
        </p>
        <p class="whd-inner-content whd-inner-content--12">
          Brand Supply Chain Optimization
        </p>
        <p class="whd-inner-content whd-inner-content--13">
          HP’s Vertical Integration
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhyHpDigital',
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/why-digital/digital-value/why-hp-digital';
</style>
