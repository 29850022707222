<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="container-xl">
      <div class="section__title section__title--digital-value text-center">
        <h2 class="title">
          {{ subSections[currentSubsection].label }}
        </h2>

        <p v-if="subSections[currentSubsection].description" class="text title--small ">
          {{ subSections[currentSubsection].description }}
        </p>
      </div>

      <div class="wrap-sidetabs">
        <SubsectionNavLeft
          :currentComponent="subSections[currentSubsection]"
          gtmSubsection="Digital Value Proposition"
          parent="Why Digital"
          :subSections="subSections"
          @click-subnav="changeTab" >
          <component
            :data="getData"
            :is="subSections[currentSubsection].component" />
        </SubsectionNavLeft>
      </div>
    </div>

    <template v-if="currentPrinter === 1">
      <ShareButton
        :hash="hashify(`${$t('main-nav.why-digital')} - ${$t('why-digital.submenu.digital-value')} - ${printerName}`)"
        :shareLabel="`${$t('main-nav.why-digital')} - ${$t('why-digital.submenu.digital-value')} - ${printerName}`"
        :shareLink="`${baseURL}shareable/why-digital/t470s_compressed.pdf`"
        @share-this="shareThis" />
    </template>
    <template v-if="currentPrinter === 2">
      <ShareButton
        :hash="hashify(`${$t('main-nav.why-digital')} - ${$t('why-digital.submenu.digital-value')} - ${printerName}`)"
        :shareLabel="`${$t('main-nav.why-digital')} - ${$t('why-digital.submenu.digital-value')} - ${printerName}`"
        :shareLink="`${baseURL}shareable/why-digital/t1195i_compressed.pdf`"
        @share-this="shareThis" />
    </template>
    <template v-if="currentPrinter === 3">
      <ShareButton
        :hash="hashify(`${$t('main-nav.why-digital')} - ${$t('why-digital.submenu.digital-value')} - ${printerName}`)"
        :shareLabel="`${$t('main-nav.why-digital')} - ${$t('why-digital.submenu.digital-value')} - ${printerName}`"
        :shareLink="`${baseURL}shareable/why-digital/t700i_compressed.pdf`"
        @share-this="shareThis" />
    </template>

  </div>
</template>

<script>
import * as endpoints from '@/common/endpoints';
import { filters, defaultMixins } from '@/common/mixins';

import ShareButton from '@/components/ui/ShareButton.vue';
import SubsectionNavLeft from '@/layout/SubsectionNavLeft.vue';

import WhyDigitalPrint from '@/views/why-digital/digital-value/WhyDigitalPrint.vue';
import WhyHpDigital from '@/views/why-digital/digital-value/WhyHpDigital.vue';
import WhyPageWidePress from '@/views/why-digital/digital-value/WhyPageWidePress.vue';

export default {
  name: 'DigitaValueProposition',
  mixins: [filters, defaultMixins],
  components: {
    ShareButton,
    SubsectionNavLeft,
    WhyDigitalPrint,
    WhyHpDigital,
    WhyPageWidePress,
  },
  data() {
    return {
      baseURL: endpoints.BASEURL,
      currentSubsection: 0,
    };
  },
  mounted() {
    this.showMainTabs();
  },
  methods: {
    changeTab(payload) {
      this.currentSubsection = payload.id;
    },
  },
  computed: {
    /**
     * The items in the array MUST match the components name files
     * these values are trimmed and passed as components names.
     */
    subSections() {
      const WhyPageWidePressDescription = {
        1: 'Discover new levels of efficiency and produce high-quality graphics at a lower cost with digital single-face lamination and folding carton.',
        2: 'Transform high-volume corrugated production with greater efficiency and improved economics on HP’s most productive digital preprint platform.',
        3: 'Achieve operational efficiency for high-volume production for digital single-face lamination, corrugation, and folding carton applications.',
      };

      return [
        {
          id: 0,
          component: 'WhyDigitalPrint',
          label: 'Why Digital Print?',
          description: '',
        },
        {
          id: 1,
          component: 'WhyHpDigital',
          label: 'Why HP Digital?',
          description: '',
        },
        {
          id: 2,
          component: 'WhyPageWidePress',
          label: `Why the HP PageWide ${this.printerName} Press?`,
          description: WhyPageWidePressDescription[this.currentPrinter],
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
  .wrapper-sub {
    display: flex;
    align-items: flex-start;
  }

  .section__title--digital-value {
    max-width: 700px;
    margin: 0 auto;
  }

  .wrap-sidetabs {
    margin-top: 32px;
  }
</style>
