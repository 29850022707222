<template>
   <Modal idModal="digitalPrint-4">
    <h2 class="title title--white text-center">
      TV Box Use Case
    </h2>
    <div class="tv-box-use-cases">
      <div class="tv-box-use-case-single tv-box-use-case-single--left">
        <h4 class="title title--smedium">
          From This...
        </h4>
        <img src="images/why-digital/digital-value/tv-from-this.png?v=1" alt="">
      </div>
      <div class="tv-box-use-case-single tv-box-use-case-single--right">
        <h4 class="title title--smedium">
          To This!
        </h4>
        <img src="images/why-digital/digital-value/tv-to-this.png?v=1" alt="">
      </div>
    </div>
    <div class="tv-box-benefits">
      <div class="row">
        <div class="col md-4">
          <div class="tv-box-benefits__headline">
            <span class="circle-icon-container">
              <img src="images/why-digital/digital-value/lower-cost-icon.png?v=1" alt="">
            </span>
            <h4 class="title title--smedium">
              Lower Cost
            </h4>
          </div>
          <div class="tv-box-benefits__content">
            <h5 class="title title--small title--blue">
              30% Savings
            </h5>
            <p>
              By reducing the manufacturing process steps from six to three.
            </p>
            <h5 class="title title--small title--blue">
              Eliminate Waste
            </h5>
            <p>
              Fewer processes reduces setup and manufacturing waste, and printing to demand avoids obsolescence.
            </p>
            <h5 class="title title--small title--blue">
              Reduce Paper Usage
            </h5>
            <p>
              Efficiently run rolls instead of sheets and eliminate the need for a litholabel.
              Reduce over all paper usage by up to 30% and maintain the same ECT.
            </p>
          </div>
        </div>
        <div class="col md-4">
          <div class="tv-box-benefits__headline">
            <span class="circle-icon-container">
              <img src="images/why-digital/digital-value/faster-icon.png?v=1" alt="">
            </span>
            <h4 class="title title--smedium">
              Faster
            </h4>
          </div>
          <div class="tv-box-benefits__content">
            <h5 class="title title--small title--blue">
              Print to Demand
            </h5>
            <p>
              Print and convert against firm demand, not forecasted demand.
            </p>
            <h5 class="title title--small title--blue">
              Fast Speed to Market
            </h5>
            <p>
              Increase efficiency and reduce production time to shrink lead times from weeks to 10 days or less.
            </p>
          </div>
        </div>
        <div class="col md-4">
          <div class="tv-box-benefits__headline">
            <span class="circle-icon-container">
              <img src="images/why-digital/digital-value/better-icon.png?v=1" alt="">
            </span>
            <h4 class="title title--smedium">
              Better
            </h4>
          </div>
          <div class="tv-box-benefits__content">
            <h5 class="title title--small title--blue">
              Reduced Environmental impact
            </h5>
            <p>
              Remove the need for offset labels and associated glues, and reduce waste throughout manufacturing.
            </p>
            <h5 class="title title--small title--blue">
              Uncompromising Quality
            </h5>
            <p>
              Flexibility to easily update and version graphics.
            </p>
            <h5 class="title title--small title--blue">
              Color Consistency
            </h5>
            <p>
              During and between print runs.
            </p>
          </div>
        </div>
      </div>
    </div>
   </Modal>
</template>

<script>
import Modal from '@/components/modal/Modal.vue';

export default {
  name: 'WhyDigitalPrintModal4',
  components: {
    Modal,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/why-digital/digital-value/why-digital-print/modal4';
</style>
