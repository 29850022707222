<template>
  <div class="wpw-press">
    <div class="wpw-press__icon-text">
      <div class="wpw-press__icon-text-container wpw-press__icon-text-container-1">
        <div class="wpw-press__icon-text-container-img">
          <img src="images/why-digital/digital-value/wpw-press-icon-1.png">
        </div>
        <p>
          Contribute to circular economy goals across a wide range of applications
        </p>
      </div>
      <div class="wpw-press__icon-text-container wpw-press__icon-text-container-2">
        <div class="wpw-press__icon-text-container-img">
          <img src="images/why-digital/digital-value/wpw-press-icon-2.png">
        </div>
        <p>
          Fuel your success beyond the press
        </p>
      </div>
    </div>

    <img
      v-if="currentPrinter === 1"
      src="images/why-digital/digital-value/wpw-press-t400s-iso.png?v=5"
      class="printer-img"
      alt="Pagewide T470S">
    <img
      v-if="currentPrinter === 2"
      src="images/why-digital/digital-value/wpw-press-t1195i-iso.png?v=2"
      class="printer-img"
      alt="Pagewide T1195i">
    <img
      v-if="currentPrinter === 3"
      src="images/why-digital/digital-value/wpw-press-t700i-iso.png?v=3"
      class="printer-img"
      alt="Pagewide t700i">

    <div class="wpw-press__icon-text">
      <div class="wpw-press__icon-text-container wpw-press__icon-text-container-3">
        <div class="wpw-press__icon-text-container-img">
          <img src="images/why-digital/digital-value/wpw-press-icon-3.png">
        </div>
        <p v-if="currentPrinter === 1">
          Economical & productive printing for litho replacement in lamination and folding carton
        </p>
        <p v-if="currentPrinter === 2">
          Efficient and economical high-volume production for corrugated preprint
        </p>
        <p v-if="currentPrinter === 3">
          Increase capacity and reduce costs for paper-based packaging production
        </p>
      </div>
      <div class="wpw-press__icon-text-container wpw-press__icon-text-container-4">
        <div class="wpw-press__icon-text-container-img">
          <img src="images/why-digital/digital-value/wpw-press-icon-4.png">
        </div>
        <p v-if="currentPrinter === 1">
          Achieve consistent offset quality with six-color printing
        </p>
        <p v-if="currentPrinter === 2">
          Achieve consistent offset quality with six-color printing
        </p>
        <p v-if="currentPrinter === 3">
          Achieve consistent offset quality and vibrant color for high-graphics packaging
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultMixins } from '@/common/mixins';

export default {
  name: 'WhyPageWidePress',
  mixins: [defaultMixins],
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/why-digital/digital-value/why-page-wide-press';
</style>
