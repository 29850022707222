<template>
  <div class="row justify-content-center">
    <div class="col-md-10">
      <div class="nozzle__img js-nozzle-img">
        <span>
          {{ $t('technology.HP-TIJ.redundancy.img-label') }}
        </span>
        <img
          class="main-img"
          :src="`${cmsURL}assets/${data.nozzle_image}`"
          :alt="data.nozzle_headline">
        <div class="nozzle__img__copy">
          <img src="images/technology/arrow.svg" alt="" class="arrow" inline>
          <p class="title title--medium title--blue">
            {{ $t('technology.HP-TIJ.redundancy.web') }}
          </p>
          <p v-if="data.id == 1">
              HP TIJ Printheads
          </p>
          <p v-else>
            HP A35i Printheads
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as endpoints from '@/common/endpoints';

export default {
  name: 'NozzleRedundancy',
  props: {
    data: Object,
  },
  data() {
    return {
      cmsURL: endpoints.CMS,
    };
  },
};
</script>

<style lang="scss" scoped>
  @import '@/scss/views/technology/tij/nozzle-redundancy.scss';
</style>
